import { useCallback, useEffect, useMemo, useState, MouseEvent, JSX } from "react";
import { Box, Button, Chip, Collapse, Divider, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Add, Check, MoreVert } from "@mui/icons-material";
import dayjs from "dayjs";

import {
  ApprovalRequestDisciplineStatus,
  ApprovalRequestStatus,
  DisciplineTrackingComment,
  DisciplineTrackingCommentStatus,
  InternalDependency
} from "@/interfaces";
import { useARContext, useAuthorization, useDisciplineTabContext } from "@/context";
import { useUpdateDisciplineTrackingComment } from "@/hooks";
import { DateInputField } from "@/components/fields";
import { ExpandToggle } from "@/components/shared";
import { TrackingCommentNotes } from "./TrackingCommentNotes";
import { AddTrackingCommentNote } from "./AddTrackingCommentNote";
import { SelectTrackingCommentDependency } from "./SelectTrackingCommentDependency";

const disciplineCommentStatus = {
  [DisciplineTrackingCommentStatus.InProgress]: "In progress",
  [DisciplineTrackingCommentStatus.Completed]: "Completed"
};

interface TrackingCommentProps {
  disciplineTrackingComment: DisciplineTrackingComment;
  updateTrackingComment: (trackingCommentId: string, comment: DisciplineTrackingComment, isDirty?: boolean) => void;
}

function TrackingComment({ disciplineTrackingComment, updateTrackingComment }: TrackingCommentProps) {
  const { comment, status, expectedAuthorisationDate, trackingCommentId, notes, isInternal, isLegacy } =
    disciplineTrackingComment;

  const { isTabDirty, trackingCommentErrors, updateTrackingCommentErrors, currentARDiscipline } =
    useDisciplineTabContext();
  const { userIsSMEOfDisciplineOrAdmin, approvalRequest } = useARContext();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(menuAnchor);
  const [openNote, setOpenNote] = useState<boolean>(false);
  const { name, userId } = useAuthorization();
  const { mutate } = useUpdateDisciplineTrackingComment((updatedDisciplineTrackingComment) => {
    updateTrackingComment(trackingCommentId, updatedDisciplineTrackingComment, false);
  });
  const expectedDate = useMemo(
    () => (expectedAuthorisationDate ? dayjs(expectedAuthorisationDate) : null),
    [expectedAuthorisationDate]
  );

  const removeTrackingComment = useCallback(() => {
    updateTrackingComment(trackingCommentId, {
      ...disciplineTrackingComment,
      isChecked: false
    });
  }, [disciplineTrackingComment, trackingCommentId, updateTrackingComment]);

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const addNoteOnClickHandler = (newNote: string) => {
    if (newNote === "") return;

    updateTrackingComment(trackingCommentId, {
      ...disciplineTrackingComment,
      notes: [
        {
          id: "",
          note: newNote,
          author: name ?? "",
          created: new Date(),
          authorId: userId ?? ""
        },
        ...notes
      ]
    });

    setExpanded(true);
  };

  const completeOnClickHandler = useCallback(() => {
    mutate({
      ...disciplineTrackingComment,
      isComplete: true,
      status: DisciplineTrackingCommentStatus.Completed
    });
  }, [mutate, disciplineTrackingComment]);

  const inProgressOnClickHandler = useCallback(() => {
    mutate({
      ...disciplineTrackingComment,
      isComplete: false,
      status: DisciplineTrackingCommentStatus.InProgress
    });
  }, [mutate, disciplineTrackingComment]);

  const menuItems: JSX.Element[] = useMemo(() => {
    if (status === DisciplineTrackingCommentStatus.Completed) {
      return [
        <MenuItem
          key="in-progress"
          onClick={() => {
            inProgressOnClickHandler();
            setMenuAnchor(null);
          }}
          disabled={isTabDirty || currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised}
        >
          In Progress
        </MenuItem>
      ];
    } else if (disciplineTrackingComment.isLegacy) {
      return [
        <MenuItem
          key="complete"
          onClick={() => {
            completeOnClickHandler();
            setMenuAnchor(null);
          }}
          disabled={
            isTabDirty ||
            approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
            currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
          }
        >
          Complete
        </MenuItem>
      ];
    } else {
      return [
        <MenuItem
          key="add-note"
          onClick={() => {
            setOpenNote(true);
            setMenuAnchor(null);
          }}
        >
          Add Note
        </MenuItem>,
        <MenuItem
          key="complete"
          onClick={() => {
            completeOnClickHandler();
            setMenuAnchor(null);
          }}
          disabled={
            isTabDirty ||
            approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
            currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
          }
        >
          Complete
        </MenuItem>,
        <Divider key="divider" />,
        <MenuItem
          key="remove-tracking-comment"
          onClick={() => {
            handleCloseMenu();
            removeTrackingComment();
          }}
          disabled={
            !userIsSMEOfDisciplineOrAdmin ||
            approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
            currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
          }
        >
          Remove Tracking Comment
        </MenuItem>
      ];
    }
  }, [status, isTabDirty, inProgressOnClickHandler, completeOnClickHandler, removeTrackingComment]);

  const handleDependencyChange = (disciplineTrackingCommentDependencies: InternalDependency[]) => {
    updateTrackingCommentErrors((old) => ({
      ...old,
      [trackingCommentId]: {
        ...old[trackingCommentId],
        disciplineTrackingCommentDependencies: undefined
      }
    }));

    updateTrackingComment(trackingCommentId, {
      ...disciplineTrackingComment,
      disciplineTrackingCommentDependencies: disciplineTrackingCommentDependencies
    });
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" height="2.5rem" spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <ExpandToggle expanded={expanded} toggleExpansion={() => setExpanded((old) => !old)} />
          <Typography>{comment}</Typography>
          {isInternal && (
            <SelectTrackingCommentDependency
              selectedDependencies={disciplineTrackingComment.disciplineTrackingCommentDependencies}
              handleDependencyChange={handleDependencyChange}
              readOnly={disciplineTrackingComment.isComplete}
              error={trackingCommentErrors[trackingCommentId]?.disciplineTrackingCommentDependencies}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Chip
            label={disciplineCommentStatus[status]}
            color={status === DisciplineTrackingCommentStatus.InProgress ? "warning" : "success"}
            style={{ marginRight: "10px" }}
            size="small"
          />
          <DateInputField
            readOnly={
              !userIsSMEOfDisciplineOrAdmin ||
              approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed
            }
            label="Expected By"
            format="MMMM YYYY"
            views={["month", "year"]}
            minDate={dayjs()}
            value={expectedDate}
            disabled={
              status === DisciplineTrackingCommentStatus.Completed ||
              isLegacy ||
              currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
            }
            onChange={(value) => {
              updateTrackingCommentErrors((old) => ({
                ...old,
                [trackingCommentId]: {
                  ...old[trackingCommentId],
                  expectedAuthorisationDate: undefined
                }
              }));

              updateTrackingComment(trackingCommentId, {
                ...disciplineTrackingComment,
                expectedAuthorisationDate: !value || !value.isValid() ? undefined : value.endOf("month").toDate()
              });
            }}
            sx={{ marginRight: "5px", p: 0 }}
            slotProps={{ textField: { size: "small" } }}
            error={trackingCommentErrors[trackingCommentId]?.expectedAuthorisationDate}
          />
          <IconButton
            onClick={handleOpenMenu}
            aria-controls={menuIsOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuIsOpen ? "true" : undefined}
            disabled={
              !userIsSMEOfDisciplineOrAdmin || currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
            }
            aria-disabled={!userIsSMEOfDisciplineOrAdmin}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={menuAnchor}
            open={menuIsOpen}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
            aria-disabled={!userIsSMEOfDisciplineOrAdmin}
          >
            {menuItems}
          </Menu>
        </Stack>
      </Stack>
      <Collapse in={expanded}>
        <Box ml="3.5rem">
          <Stack
            direction="row"
            alignItems="center"
            height="2.5rem"
            spacing="1rem"
            display={status === DisciplineTrackingCommentStatus.InProgress ? "display" : "none"}
          >
            <Button
              variant="text"
              size="small"
              startIcon={<Add />}
              onClick={() => {
                setOpenNote(true);
              }}
              disabled={
                !userIsSMEOfDisciplineOrAdmin ||
                isLegacy ||
                currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
              }
              sx={{ p: 0 }}
            >
              Add Note
            </Button>
            <Button
              variant="text"
              size="small"
              startIcon={<Check />}
              disabled={
                !userIsSMEOfDisciplineOrAdmin ||
                isTabDirty ||
                approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
                currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
              }
              onClick={completeOnClickHandler}
              sx={{ p: 0 }}
            >
              Complete
            </Button>
          </Stack>
          <TrackingCommentNotes
            trackingComment={disciplineTrackingComment}
            notes={notes}
            updateTrackingCommentNotes={(notes) => {
              updateTrackingComment(trackingCommentId, {
                ...disciplineTrackingComment,
                notes
              });
            }}
          />
          <AddTrackingCommentNote
            open={openNote}
            setOpen={setOpenNote}
            disciplineTrackingComment={disciplineTrackingComment}
            addTrackingCommentNote={addNoteOnClickHandler}
          />
        </Box>
      </Collapse>
    </Box>
  );
}

export const ExistingTrackingComments = () => {
  const { trackingComments, updateTrackingComments } = useDisciplineTabContext();
  const [trackingCommentsById, setTrackingCommentsById] = useState<Record<string, DisciplineTrackingComment>>({});

  useEffect(() => {
    const indexedTrackingComments: Record<string, DisciplineTrackingComment> = {};

    trackingComments.forEach((comment) => (indexedTrackingComments[comment.trackingCommentId] = comment));

    setTrackingCommentsById(indexedTrackingComments);
  }, [trackingComments]);

  const updateTrackingComment = (trackingCommentId: string, comment: DisciplineTrackingComment, isDirty?: boolean) => {
    const updatedTrackingCommentsById = {
      ...trackingCommentsById,
      [trackingCommentId]: comment
    };

    setTrackingCommentsById(updatedTrackingCommentsById);
    updateTrackingComments(Object.values(updatedTrackingCommentsById), isDirty);
  };

  return (
    <Stack spacing="1.5rem">
      {Object.values(trackingCommentsById).map((trackingComment, index) => {
        return trackingComment.isChecked ? (
          <TrackingComment
            key={`${trackingComment.trackingCommentId}-${index}`}
            disciplineTrackingComment={trackingComment}
            updateTrackingComment={updateTrackingComment}
          />
        ) : null;
      })}
    </Stack>
  );
};
