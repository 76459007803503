import { Dispatch, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import { SetStateAction } from "jotai";

import { DisciplineTrackingComment, DisciplineTrackingCommentStatus } from "@/interfaces";
import { useDisciplineTabContext } from "@/context";
import { arraysAreEqual } from "@/utils";

interface SelectTrackingCommentsProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function indexByTrackingCommentId(
  trackingComments: DisciplineTrackingComment[]
): Record<string, DisciplineTrackingComment> {
  const indexedTrackingComments: Record<string, DisciplineTrackingComment> = {};

  trackingComments.forEach((comment) => {
    indexedTrackingComments[comment.trackingCommentId] = comment;
  });

  return indexedTrackingComments;
}

export function SelectTrackingComments({ open, setOpen }: SelectTrackingCommentsProps) {
  const { trackingComments, updateTrackingComments: setTrackingComments } = useDisciplineTabContext();
  const [selectedComments, setSelectedComments] = useState<Record<string, DisciplineTrackingComment>>({});

  useEffect(() => {
    setSelectedComments(indexByTrackingCommentId(trackingComments));
  }, [trackingComments]);

  const cancelClickHandler = () => {
    setOpen(false);
    setSelectedComments(indexByTrackingCommentId(trackingComments));
  };

  const applyClickHandler = () => {
    setOpen(false);
    setTrackingComments(Object.values(selectedComments));
  };

  const onCommentSelectHandler = (trackingCommentId: string, checked: boolean) => {
    setSelectedComments((old) => ({
      ...old,
      [trackingCommentId]: {
        ...old[trackingCommentId],
        isChecked: checked
      }
    }));
  };

  const selectionsHaveChanged = useMemo(
    () => !arraysAreEqual(trackingComments, Object.values(selectedComments)),
    [selectedComments, trackingComments]
  );

  return (
    <Dialog open={open} onClose={cancelClickHandler} fullWidth={true} maxWidth="md">
      <DialogTitle>Tracking Selection</DialogTitle>
      <DialogContent>
        <FormGroup>
          {trackingComments.map(({ trackingCommentId, comment, status, isLegacy }, index) => {
            return !isLegacy ? (
              <FormControlLabel
                key={`${trackingCommentId}-${index}`}
                control={
                  <Checkbox
                    value={trackingCommentId}
                    checked={selectedComments[trackingCommentId]?.isChecked ?? false}
                    disabled={status === DisciplineTrackingCommentStatus.Completed}
                  />
                }
                label={comment}
                onChange={(_, checked) => {
                  onCommentSelectHandler(trackingCommentId, checked);
                }}
              />
            ) : null;
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelClickHandler}>Cancel</Button>
        <Button onClick={applyClickHandler} disabled={!selectionsHaveChanged}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
