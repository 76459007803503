import { ArcgisMap, ArcgisZoom } from "@arcgis/map-components-react";
import { Grid } from "@mui/material";
// import defineCustomElements to register custom elements with the custom elements registry
import { defineCustomElements } from "@arcgis/map-components/dist/loader";
defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.30/assets" });

const { REACT_APP_ARCGIS_WEBMAP_ITEM_ID } = import.meta.env;

interface MapRenderProps {
  mapLoaded: boolean;
  setMapLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MapRender({ mapLoaded, setMapLoaded }: MapRenderProps) {
  return (
    <Grid
      data-testid="map-render-display"
      visibility={mapLoaded ? "visible" : "hidden"}
      sx={{ flexDirection: "column", flexGrow: 1, height: "600px" }}
      style={{ margin: "0 auto" }}
    >
      <ArcgisMap
        itemId={REACT_APP_ARCGIS_WEBMAP_ITEM_ID}
        onArcgisViewReadyChange={() => {
          setMapLoaded(true);
        }}
      >
        <ArcgisZoom position="bottom-right"></ArcgisZoom>
      </ArcgisMap>
    </Grid>
  );
}
