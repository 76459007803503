import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  APPROVAL_REQUEST_PATH,
  APPROVAL_REQUEST_QUERY_KEY,
  AR_STATUS_HISTORY_QUERY_KEY,
  AR_COMMENTARIES_QUERY_KEY,
  AR_DISCIPLINE_QUERY_KEY,
  AR_LIST_QUERY_KEY,
  AR_UNEXPECTED_ERROR_BANNER,
  ASSIGNED_ARS_TO_SME_QUERY_KEY,
  EMPTY_PAGINATED_RESPONSE,
  NOTIFICATIONS_QUERY_KEY,
  QA_ANSWERS_QUERY_KEY,
  RETURN_DRAFT_PERMIT_MUTATION_KEY,
  SEARCH_RESULT_QUERY_KEY,
  WC_ANSWERS_QUERY_KEY,
  PERMIT_QUERY_KEY,
  AMEND_PERMIT_MUTATION_KEY,
  APPROVAL_REQUEST_MAP_QUERY_KEY
} from "@/constants";
import {
  ARSearchResult,
  ARStatusHistory,
  AmendPermitRequest,
  ApprovalRequestBase,
  ApprovalRequestList,
  ApprovalRequestQARequest,
  ApprovalRequestQAResponse,
  ApprovalRequestStatus,
  CreateNewARRequest,
  GetARListRequest,
  GetWorkCategoryListRequest,
  GetWorkSubCategoryListRequest,
  NotificationType,
  PaginatedList,
  ReturnApprovalRequest,
  ReturnDraftPermitRequest,
  SMEAssignedApprovalRequestList,
  UpdateApprovalRequest,
  WCAnswerItem,
  AddApprovalRequestMap,
  MapItem
} from "@/interfaces";
import {
  createNewApprovalRequest,
  getApprovalRequestWorkCategory,
  getWorkCategoryList,
  getWorkSubCategoryList,
  returnApprovalRequest
} from "@/services";
import { useARContext } from "@/context";
import { generatePath, useNavigate } from "react-router-dom";
import { apiClient } from "@/api/client";
import { GetSMEListRequest } from "@/interfaces/api/requests/SMEApprovalRequests";

const approvalRequest = "/approvalRequest";
const approvalRequestReturn = "/approvalRequestReturn";
const smeApprovalRequest = "/approvalRequestDiscipline";

const reviewStatuses = [ApprovalRequestStatus.Submitted, ApprovalRequestStatus.Distributed];

export function useApprovalRequestById(id?: string, onError?: () => void) {
  return useQuery({
    queryKey: [APPROVAL_REQUEST_QUERY_KEY, id],
    queryFn: () => apiClient.get<ApprovalRequestBase>(`${approvalRequest}/${id}`).then((response) => response.data),
    enabled: id !== undefined,
    onError: () => {
      onError?.();
    }
  });
}

export function useQuestionAndAnswers(
  request: ApprovalRequestQARequest,
  onSuccessCallback: (response: ApprovalRequestQAResponse) => void
) {
  return useQuery({
    queryKey: [QA_ANSWERS_QUERY_KEY, request.approvalRequestId],
    queryFn: () =>
      apiClient
        .get<ApprovalRequestQAResponse>(`${approvalRequest}/questionAnswer`, {
          params: { ...request }
        })
        .then((response) => response.data),
    initialData: {
      approvalRequestId: request.approvalRequestId,
      approvalRequestTypeId: request.approvalRequestTypeId,
      questions: [],
      answers: []
    },
    onSuccess: (response) => {
      onSuccessCallback(response);
    }
  });
}

export function useWorkCategories(request: GetWorkCategoryListRequest) {
  return useQuery({
    queryKey: ["ar-work-categories", request.approvalRequestTypeId],
    queryFn: () => getWorkCategoryList(request),
    initialData: []
  });
}

export function useWorkSubCategories(request: GetWorkSubCategoryListRequest) {
  return useQuery({
    queryKey: ["ar-work-sub-categories"],
    queryFn: () => getWorkSubCategoryList(request),
    initialData: []
  });
}

export function useWorkCategoryAnswers(
  approvalRequestId: string,
  onSuccessCallback?: (wcAnswers: WCAnswerItem[]) => void
) {
  return useQuery({
    queryKey: [WC_ANSWERS_QUERY_KEY, approvalRequestId],
    queryFn: () => getApprovalRequestWorkCategory({ approvalRequestId }),
    onSuccess: (data) => {
      onSuccessCallback?.(data);
    },
    initialData: []
  });
}

export function useUpdateApprovalRequest(approvalRequestId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: UpdateApprovalRequest) =>
      apiClient.put<ApprovalRequestBase>(`${approvalRequest}/${request.id}`, request).then((response) => response.data),
    onSuccess: (response) => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_STATUS_HISTORY_QUERY_KEY, approvalRequestId]);
      reviewStatuses.includes(response.approvalRequestStatus) &&
        queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([
        NOTIFICATIONS_QUERY_KEY,
        undefined,
        false,
        undefined,
        [NotificationType.Actionable, NotificationType.InApp]
      ]);
      queryClient.invalidateQueries([PERMIT_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useDeleteApprovalRequest() {
  return useMutation({
    mutationFn: (approvalRequestId: string) =>
      apiClient.delete<boolean>(`${approvalRequest}/draft/${approvalRequestId}`).then((response) => response.data)
  });
}

export function useReturnApprovalRequest(
  approvalRequestId: string,
  onSuccessCallback: (data: ApprovalRequestBase) => void
) {
  const queryClient = useQueryClient();
  const { appendAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (returnApprovalRequestDetails: ReturnApprovalRequest) =>
      returnApprovalRequest(returnApprovalRequestDetails),
    onSuccess: (data) => {
      onSuccessCallback(data);
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_STATUS_HISTORY_QUERY_KEY, approvalRequestId]);
      reviewStatuses.includes(data.approvalRequestStatus) &&
        queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([
        NOTIFICATIONS_QUERY_KEY,
        undefined,
        false,
        undefined,
        [NotificationType.Actionable, NotificationType.InApp]
      ]);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}

export function useReturnDraftPermit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [RETURN_DRAFT_PERMIT_MUTATION_KEY],
    mutationFn: (request: ReturnDraftPermitRequest) => apiClient.post(`${approvalRequestReturn}/draft-permit`, request),
    onSuccess: (_, { approvalRequestId }) => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_DISCIPLINE_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_STATUS_HISTORY_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useCreateApprovalRequest() {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (request: CreateNewARRequest) => createNewApprovalRequest(request),
    onSuccess: (response) => {
      const path = generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: response.id });
      navigate(path);
    }
  });
}

export function useGetARSearch(
  searchTerm?: string,
  peopleSearchTerm?: string,
  hubId?: string,
  siteId?: string,
  businessUnitId?: string,
  projectId?: string,
  approvalRequestTypeId?: string,
  clearingOption?: string,
  pageNumber: number = 1,
  pageSize: number = 50
) {
  return useQuery({
    queryKey: [SEARCH_RESULT_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<PaginatedList<ARSearchResult>>(`${approvalRequest}/search`, {
          params: {
            searchTerm,
            peopleSearchTerm,
            hubId,
            siteId,
            businessUnitId,
            projectId,
            approvalRequestTypeId,
            clearingOption,
            pageNumber,
            pageSize
          }
        })
        .then((response) => response.data)
  });
}

export function useApprovalRequestList(params: GetARListRequest) {
  return useQuery({
    queryKey: [AR_LIST_QUERY_KEY, params],
    queryFn: () =>
      apiClient
        .get<ApprovalRequestList>(approvalRequest, {
          params: { ...params },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    initialData: EMPTY_PAGINATED_RESPONSE
  });
}

export function useSMEAssignedARsList(params: GetSMEListRequest) {
  return useQuery({
    queryKey: [ASSIGNED_ARS_TO_SME_QUERY_KEY, params],
    queryFn: () =>
      apiClient
        .get<SMEAssignedApprovalRequestList>(`${smeApprovalRequest}/assigned/paginated`, {
          params: { ...params },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    initialData: EMPTY_PAGINATED_RESPONSE
  });
}

export function useGetApprovalRequestStatusHistory(approvalRequestId: string) {
  return useQuery({
    queryKey: [AR_STATUS_HISTORY_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient
        .get<ARStatusHistory[]>(`${approvalRequest}/${approvalRequestId}/statusHistory`)
        .then((response) => response.data)
  });
}

export function useAmendPermit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [AMEND_PERMIT_MUTATION_KEY],
    mutationFn: (request: AmendPermitRequest) => apiClient.post(`${approvalRequestReturn}/amend-permit`, request),
    onSuccess: (_, { approvalRequestId }) => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useGetApprovalRequestMap(approvalRequestId: string) {
  return useQuery({
    queryKey: [APPROVAL_REQUEST_MAP_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient.get<MapItem>(`${approvalRequest}/${approvalRequestId}/map`).then((response) => response.data)
  });
}

export function useAddApprovalRequestMap(approvalRequestId: string, onSuccessCallback?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: AddApprovalRequestMap) =>
      apiClient.post<MapItem>(`${approvalRequest}/${approvalRequestId}/map`, request.fileData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }),
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([APPROVAL_REQUEST_MAP_QUERY_KEY, approvalRequestId]);
    }
  });
}
