import { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";

import { colours } from "@/theme/colour";
import { useAuthorization, useAttachmentLinkContext } from "@/context";
import { AttachmentLink } from "@/interfaces";

interface AttachmentLinkContentProps {
  attachmentLink: AttachmentLink;
}

function AttachmentLinkContent({ attachmentLink }: AttachmentLinkContentProps) {
  const [attachmentTitleIsHovered, setAttachmentTitleIsHovered] = useState(false);

  const linkStyles = {
    textDecoration: "none",
    color: attachmentTitleIsHovered ? colours.hyperlink : "inherit",
    transition: "color 0.2s ease-in-out",
    cursor: "pointer",
    height: "100%"
  };

  const toggleTitleOnHover = () => setAttachmentTitleIsHovered((prev) => !prev);

  return (
    <a
      onMouseEnter={toggleTitleOnHover}
      onMouseLeave={toggleTitleOnHover}
      style={linkStyles}
      href={attachmentLink.url}
      target="_blank"
      rel="noreferrer"
    >
      <Typography overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" maxWidth="30rem">
        {attachmentLink.name}
      </Typography>
    </a>
  );
}

export function AttachmentLinkDisplay() {
  const { userId, isAdmin } = useAuthorization();
  const { attachmentLinks, deleteAttachmentLink, setAttachmentLinkModalConfig } = useAttachmentLinkContext();

  return (
    <Grid container px="2rem">
      {attachmentLinks.map((attachmentLink) => {
        const isActionable = attachmentLink.createdBy == userId || isAdmin;

        return (
          <Grid item key={attachmentLink.id} xs={12}>
            <Grid container alignItems="center">
              <Grid item flex={22}>
                <AttachmentLinkContent attachmentLink={attachmentLink} />
              </Grid>
              <Grid item flex={1}>
                <IconButton
                  onClick={() => {
                    setAttachmentLinkModalConfig({
                      mode: "Edit",
                      modalIsOpen: true,
                      attachmentLinkInEdit: attachmentLink
                    });
                  }}
                  sx={{
                    visibility: isActionable ? "visible" : "hidden"
                  }}
                  data-testid={`attachment-link-edit-icon-${attachmentLink.id}`}
                >
                  <EditIcon color="action" />
                </IconButton>
              </Grid>
              <Grid item flex={1}>
                <IconButton
                  onClick={() => {
                    deleteAttachmentLink(attachmentLink.id);
                  }}
                  sx={{
                    visibility: isActionable ? "visible" : "hidden"
                  }}
                  data-testid={`attachment-link-delete-icon-${attachmentLink.id}`}
                >
                  <ClearIcon color="action" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
