import { useMemo } from "react";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { formatMonthYear } from "@/utils";
import { useARContext, useDisciplineTabContext } from "@/context";
import dayjs, { Dayjs } from "dayjs";

export function ApprovalRequestDetails() {
  const { approvalRequest } = useARContext();
  const { trackingComments } = useDisciplineTabContext();

  const maxExpectedAuthorisationDate = useMemo(() => {
    let maxAuthorisationDate: Dayjs | null = null;

    trackingComments.forEach(({ isChecked, expectedAuthorisationDate }) => {
      const authorisationDate = dayjs(expectedAuthorisationDate ?? null);

      if (!authorisationDate || !authorisationDate.isValid() || !isChecked) {
        return;
      }

      if (maxAuthorisationDate === null || authorisationDate > maxAuthorisationDate) {
        maxAuthorisationDate = authorisationDate;
      }
    });

    return maxAuthorisationDate ? formatMonthYear(maxAuthorisationDate) : "";
  }, [trackingComments]);

  return (
    <Stack direction="row" spacing="1rem">
      <TextField
        variant="outlined"
        label={"Title"}
        name={"title"}
        value={approvalRequest.title}
        sx={{ flex: 4 }}
        size="small"
        InputProps={{
          readOnly: true
        }}
      />
      <TextField
        variant="outlined"
        label={"Site"}
        name={"site"}
        sx={{ flex: 1 }}
        size="small"
        value={approvalRequest.site}
        InputProps={{
          readOnly: true
        }}
      />
      <TextField
        variant="outlined"
        label={"Expected Auth Date"}
        name={"expectedAuthorisationDate"}
        sx={{ flex: 1 }}
        size="small"
        value={maxExpectedAuthorisationDate}
        InputProps={{
          readOnly: true
        }}
      />
    </Stack>
  );
}
