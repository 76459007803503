import { MANDATORY_FIELD } from "@/constants";
import * as yup from "yup";

const baseReturnApprovalRequestSchema = yup.object({
  approvalRequestId: yup.string().required(MANDATORY_FIELD.DEFAULT),
  reasons: yup.array().of(yup.string()).min(1).required(MANDATORY_FIELD.DEFAULT),
  comments: yup.string().optional()
});

const commentsRequiredSchema = yup.object({
  comments: yup.string().trim().required(MANDATORY_FIELD.DEFAULT)
});

const commentsOptionalSchema = yup.object({
  comments: yup.string().optional()
});

export const returnApprovalRequestSchema = (isCommentRequired: boolean) => {
  let returnApprovalRequestSchema = baseReturnApprovalRequestSchema;
  returnApprovalRequestSchema = isCommentRequired
    ? returnApprovalRequestSchema.concat(commentsRequiredSchema)
    : returnApprovalRequestSchema.concat(commentsOptionalSchema);
  return returnApprovalRequestSchema;
};
