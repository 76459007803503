export interface UserProfile {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  companyFunction: string;
}

export enum UserRole {
  TabOwner = "TabOwner",
  Coordinator = "Coordinator",
  Endorser = "Endorser",
  Superintendent = "Superintendent",
  ApprovalsDeliveryTeam = "ApprovalsDeliveryTeam",
  Guest = "Guest",
  Administrator = "Administrator"
}

export enum LAMSRole {
  Guest = 1,
  Admin = 2,
  Endorser = 3,
  ApprovalDeliveryTeam = 4,
  Coordinator = 5,
  TabOwner = 6
}
