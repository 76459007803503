import { MANDATORY_FIELD } from "@/constants";
import * as yup from "yup";

export const ARCSLayersSchema = yup.object().shape({
  arcGisServiceId: yup.string().required(MANDATORY_FIELD.DEFAULT),
  arcGisLayerId: yup
    .number()
    .required(MANDATORY_FIELD.DEFAULT)
    .test("Not-default-value", "ArcGIS Layer is a required field.", (arcGisLayerId) => {
      return arcGisLayerId >= 0 ? true : false;
    }),
  requestType: yup
    .number()
    .required(MANDATORY_FIELD.DEFAULT)
    .test("Not-default-value", "Type is a required field.", (requestType) => {
      return requestType >= 0 ? true : false;
    }),
  recordStatus: yup
    .number()
    .required(MANDATORY_FIELD.DEFAULT)
    .test("Not-default-value", "Status is a required field.", (recordStatus) => {
      return recordStatus >= 0 ? true : false;
    })
});
