import { RouteObject, createBrowserRouter } from "react-router-dom";

import { APPROVAL_REQUEST_PATH, PATH } from "@/constants";
import { BaseLayout } from "@/layouts";
import { AdminSettingsRoutes } from "./adminSettingsRoutes";
import { DetectedInterceptRoutes } from "./detectedInterceptRoutes";
import { ApprovalRequestTabRoutes } from "./approvalRequestRoutes";
import { HomeRoutes } from "./homePageRoutes";
import { MyRequestsRoutes } from "./myRequestsRoutes";
import { PermitPrintPreview } from "@/components/my-requests/permit/PermitPrintPreview";

const AppRoutes: RouteObject[] = [
  {
    path: PATH.BASE,
    element: <BaseLayout />,
    children: [HomeRoutes, AdminSettingsRoutes, DetectedInterceptRoutes, MyRequestsRoutes, ApprovalRequestTabRoutes]
  },
  {
    path: APPROVAL_REQUEST_PATH.PERMIT_PREVIEW,
    element: <PermitPrintPreview />
  }
];

export const router = createBrowserRouter(AppRoutes);
