import { Box, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import { AR_STATUS_TIMELINE_LOCATORS } from "@/constants";
import { useGetApprovalRequestStatusHistory } from "@/hooks/api/ApprovalRequestHooks";
import { TimelineStatus } from "@/interfaces";
import { dateFormatter } from "@/utils";

interface ARStatusTimelineProps {
  approvalRequestId: string;
}

const renderTimelineDot = (status: TimelineStatus) => {
  if (status === TimelineStatus.Current) {
    return <TimelineDot color="primary" data-testid={AR_STATUS_TIMELINE_LOCATORS.STATUS_DOT} />;
  } else if (status === TimelineStatus.Future) {
    return <TimelineDot variant="outlined" data-testid={AR_STATUS_TIMELINE_LOCATORS.STATUS_DOT} />;
  } else {
    return <TimelineDot data-testid={AR_STATUS_TIMELINE_LOCATORS.STATUS_DOT} />;
  }
};

export function ARStatusTimeline({ approvalRequestId }: ARStatusTimelineProps) {
  const { data: statusHistory } = useGetApprovalRequestStatusHistory(approvalRequestId);

  return (
    <Box flex={1}>
      <Typography mb="1rem">Status</Typography>
      <Timeline>
        {statusHistory &&
          statusHistory?.map(({ statusName, dateAssigned, status, dateFormat, fontStyle }, index) => (
            <TimelineItem
              key={statusName}
              data-testid={`${AR_STATUS_TIMELINE_LOCATORS.STATUS_DATE_ITEM}-${statusName
                .toLowerCase()
                .replaceAll(" ", "-")}`}
            >
              <TimelineOppositeContent data-testid={AR_STATUS_TIMELINE_LOCATORS.STATUS_DATE_LABEL}>
                {statusName}
              </TimelineOppositeContent>
              <TimelineSeparator>
                {renderTimelineDot(status)}
                {index !== statusHistory.length - 1 ? <TimelineConnector /> : null}
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  fontStyle={fontStyle}
                  color={"grey"}
                  data-testid={AR_STATUS_TIMELINE_LOCATORS.STATUS_DATE_TEXT}
                >
                  {dateFormatter(dateAssigned, dateFormat)}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </Box>
  );
}
