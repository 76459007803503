import { useRef, useState, DragEvent, ChangeEvent } from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { ACCEPTED_FILE_EXTENSIONS } from "@/constants";
import { colours } from "@/theme/colour";

const defaultUploadBackgroundColor = "rgba(0, 0, 0, 0.05)";
const onHoverBackgroundColor = "rgba(0, 91, 209, 0.08)";
interface AttachmentUploadBoxProps {
  handleUpload: (file: File) => void;
  fillHeight?: boolean;
  customFooter?: JSX.Element;
}

export function AttachmentUploadBox({ handleUpload, fillHeight, customFooter }: AttachmentUploadBoxProps) {
  const [uploadBoxIsHovered, setUploadBoxIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setUploadBoxIsHovered(true);
    setErrorMessage("");
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setUploadBoxIsHovered(false);
    setUploadBoxIsHovered(false);
  };

  const handleManualUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files === null) return;
    if (files.length <= 0) return;
    const uploadedFileList = Array.from(files);
    uploadedFileList.forEach((file) => {
      handleUpload(file);
    });
    setErrorMessage("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDropUpload = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setUploadBoxIsHovered(false);
    const files = event.dataTransfer.files;
    if (files.length < 0) return;
    const uploadedFileList = Array.from(files);

    uploadedFileList.forEach((file) => {
      handleUpload(file);
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Grid
      container
      component="div"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDropUpload}
      border={`1px dashed ${uploadBoxIsHovered ? onHoverBackgroundColor : defaultUploadBackgroundColor}`}
      height={fillHeight ? "100%" : "8rem"}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      sx={{
        backgroundColor: uploadBoxIsHovered ? onHoverBackgroundColor : defaultUploadBackgroundColor,
        marginTop: "auto",
        borderRadius: 1
      }}
      data-testid="attachment-upload-box"
    >
      <Avatar sx={{ bgcolor: "#1976D21F" }}>
        <UploadFileIcon color="primary" />
      </Avatar>

      <div style={{ display: "flex", paddingTop: "0.75rem" }}>
        <input
          ref={fileInputRef}
          id="fileInput"
          type="file"
          multiple
          accept={ACCEPTED_FILE_EXTENSIONS.join(", ")}
          style={{ display: "none" }}
          onChange={(event) => {
            handleManualUpload(event);
          }}
        />
        <label htmlFor="fileInput">
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer"
              },
              textDecoration: "underline",
              color: colours.hyperlink
            }}
          >
            Click to upload
          </Typography>
        </label>
        <Typography variant="body1">&nbsp;or drag and drop.</Typography>
      </div>
      {customFooter}
      <Typography variant="body1" color="error">
        {errorMessage}
      </Typography>
    </Grid>
  );
}
