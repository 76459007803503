import { Avatar } from "@mui/material";
import { useAuthorization } from "@/context";
import { useProfilePhoto } from "@/hooks";

interface UserAvatarProps {
  fontSize: string;
  avatarWidth: string;
  avatarHeight: string;
  avatarName?: string;
  avatarUserId?: string;
}

export function UserAvatar({ avatarWidth, avatarHeight, fontSize, avatarUserId, avatarName }: UserAvatarProps) {
  const { name: currentUserName, userId: currentUserId } = useAuthorization();

  const userId = avatarUserId ?? currentUserId ?? "";
  const userName = avatarName ?? currentUserName ?? "";

  const { data } = useProfilePhoto(userId, "120x120");

  return (
    <Avatar
      sx={{
        width: avatarWidth,
        height: avatarHeight,
        fontSize: fontSize
      }}
      alt={userName}
      src={data}
    />
  );
}
