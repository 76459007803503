import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  styled,
  SxProps,
  Typography
} from "@mui/material";
import { useSetAtom } from "jotai";
import { generatePath, useNavigate } from "react-router-dom";

import { ApprovalRequestType, CreateNewARRequest } from "@/interfaces";
import { approvalRequestTypeAtom } from "@/stores";
import { getFilteredApprovalRequestTypes, createNewApprovalRequest } from "@/services";
import { APPROVAL_REQUEST_PATH } from "@/constants";

export function NewApprovalRequestType() {
  const navigate = useNavigate();
  const setApprovalRequestType = useSetAtom(approvalRequestTypeAtom);
  const [approvalRequestTypes, setApprovalRequestTypes] = useState<ApprovalRequestType[]>([]);

  const newArTypeStackStyle: SxProps = {
    flexDirection: "row",
    maxWidth: "100%",
    overflow: "auto",
    alignItems: "flex-start"
  };

  const arTypeCardStyle: SxProps = {
    height: "100%",
    width: "25vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    px: 0.5,
    boxShadow: 0
  };

  const cardPaddingStyle: SxProps = {
    py: 0.3
  };

  const cardContentStyle: SxProps = {
    height: "4.8em",
    lineHeight: "1.2em",
    py: 0.1,
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const cardActionStyle: SxProps = {
    alignItems: "flex-end",
    justifyContent: "flex-start",
    bottom: 0,
    width: "100%"
  };

  const titleStyle: SxProps = {
    fontSize: "1.2em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  };

  const RaiseRequestButton = styled(Button)({
    textTransform: "none",
    size: "small",
    color: "primary"
  });

  useEffect(() => {
    getFilteredApprovalRequestTypes({ isEnabled: true }).then(({ approvalRequestTypeList }) => {
      setApprovalRequestTypes(approvalRequestTypeList);
    });
  }, []);

  const handleRaiseRequest = async (requestType: ApprovalRequestType) => {
    setApprovalRequestType(requestType.name);
    const newArRequest: CreateNewARRequest = {
      approvalRequestTypeId: requestType.id
    };

    const result = await createNewApprovalRequest(newArRequest);

    const id = result.id;
    const path = generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: id });
    navigate(path);
  };

  return (
    <Box>
      <Typography sx={titleStyle} gutterBottom variant="h6">
        New
      </Typography>
      <br />
      <Stack sx={newArTypeStackStyle}>
        {approvalRequestTypes ? (
          <>
            {approvalRequestTypes.map((approvalRequestType, index) => (
              <Fragment key={index}>
                {index !== 0 ? (
                  <Divider key={`${index}-divider`} orientation="vertical" variant="fullWidth" flexItem />
                ) : null}
                <Card key={`${index}-card`} sx={arTypeCardStyle}>
                  <CardActionArea sx={cardPaddingStyle}>
                    <CardHeader
                      sx={cardPaddingStyle}
                      title={<Typography sx={titleStyle}>{approvalRequestType.name}</Typography>}
                    />
                    <CardContent sx={cardContentStyle}>
                      <Typography variant="caption" color="text.secondary">
                        {approvalRequestType.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions sx={cardActionStyle}>
                    <RaiseRequestButton onClick={() => handleRaiseRequest(approvalRequestType)}>
                      Raise Request
                    </RaiseRequestButton>
                  </CardActions>
                </Card>
              </Fragment>
            ))}
          </>
        ) : null}
      </Stack>
    </Box>
  );
}
