import { Alert, AlertTitle } from "@mui/material";

import { AlertBannerConfig } from "@/interfaces";
import { useARContext } from "@/context";

interface AlertBannerProps extends AlertBannerConfig {}

export function ApprovalRequestAlertBanner({ id, title, message, severity = "error" }: AlertBannerProps) {
  const { removeAlertBanner } = useARContext();

  return (
    <Alert
      severity={severity}
      onClose={() => {
        removeAlertBanner([id]);
      }}
      data-testid={`${id}-${severity}-alert-banner`}
      sx={{ position: "sticky", top: "70px", zIndex: "100", whiteSpace: "pre-line" }}
    >
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
}
