import { apiClient } from "@/api/client";
import {
  GetARWorkCategoryListByRequest,
  GetARWorkCategoryListByResponse,
  GetWorkCategoryListRequest,
  GetWorkCategoryListResponse,
  GetWorkSubCategoryListRequest,
  GetWorkSubCategoryListResponse
} from "@/interfaces/api";

// Put all api path in a file due to all api are being use together at the moment.
// If there are any scenario that required the to reuse the api,
// please move the api out of this file to become standalone.
const workCategoryApiPath = "/WorkCategory";
const workSubCategoryApiPath = "/WorkSubCategory";
const approvalRequestWorkCategoryApiPath = "/ApprovalRequestWorkCategory";

export const getWorkCategoryList = (request: GetWorkCategoryListRequest) =>
  apiClient
    .get<GetWorkCategoryListResponse>(workCategoryApiPath, {
      params: { ...request }
    })
    .then((response) => response.data.optionList);

export const getWorkSubCategoryList = (request: GetWorkSubCategoryListRequest) =>
  apiClient
    .get<GetWorkSubCategoryListResponse>(workSubCategoryApiPath, {
      params: { ...request }
    })
    .then((response) => response.data.workSubCategoryList);

export const getApprovalRequestWorkCategory = (request: GetARWorkCategoryListByRequest) =>
  apiClient
    .get<GetARWorkCategoryListByResponse>(approvalRequestWorkCategoryApiPath, {
      params: { ...request }
    })
    .then((response) => response.data.approvalRequestWorkCategoryList);
