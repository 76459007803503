import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewCommentary,
  getCommentary,
  deleteCommentary,
  updateCommentary,
  updateApprovalRequestReturn
} from "@/services";
import { cleanUpRTEContent } from "@/utils";
import {
  APPROVAL_REQUEST_QUERY_KEY,
  AR_COMMENTARIES_QUERY_KEY,
  AR_STATUS_HISTORY_QUERY_KEY,
  AR_UNEXPECTED_ERROR_BANNER,
  NOTIFICATIONS_QUERY_KEY
} from "@/constants";
import { ApprovalRequestReturnStatus, NotificationType } from "@/interfaces";
import { useARContext } from "@/context";
import { useEffect } from "react";

export function useGetCommentary(approvalRequestId?: string) {
  const { appendAlertBanner } = useARContext();

  const { error, ...rest } = useQuery({
    queryKey: [AR_COMMENTARIES_QUERY_KEY, approvalRequestId],
    queryFn: () => getCommentary({ approvalRequestId })
  });

  useEffect(() => {
    if (error) {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  }, [error, appendAlertBanner]);

  return { ...rest, error };
}

export function useAddCommentary(
  approvalRequestId: string,
  parentCommentaryId?: string,
  onSuccessCallback?: () => void
) {
  const queryClient = useQueryClient();
  const { appendAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (comment?: string) => {
      const trimmedComment = comment ? cleanUpRTEContent(comment) : comment;
      return addNewCommentary({ approvalRequestId, comment: trimmedComment, parentCommentaryId });
    },
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}

export function useUpdateCommentary(approvalRequestId: string, id: string, onSuccessCallback?: () => void) {
  const queryClient = useQueryClient();
  const { appendAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (comment?: string) => {
      const trimmedComment = comment ? cleanUpRTEContent(comment) : comment;
      return updateCommentary({ approvalRequestId, comment: trimmedComment, id });
    },
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}
export function useDeleteCommentary(approvalRequestId: string) {
  const queryClient = useQueryClient();
  const { appendAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (parentCommentaryId: string) => deleteCommentary(parentCommentaryId),
    onSuccess: () => {
      queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}

export function useCommentaryDecideReturnRequest(
  approvalRequestId: string,
  commentaryId: string,
  onSuccessCallback?: () => void
) {
  const queryClient = useQueryClient();
  const { appendAlertBanner } = useARContext();

  return useMutation({
    mutationFn: (decision: ApprovalRequestReturnStatus) => {
      return updateApprovalRequestReturn({ approvalRequestId, commentaryId, decision });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_STATUS_HISTORY_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([
        NOTIFICATIONS_QUERY_KEY,
        undefined,
        false,
        undefined,
        [NotificationType.Actionable, NotificationType.InApp]
      ]);
      onSuccessCallback?.();
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}
