import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";

import { UserRole } from "@/interfaces";

export interface AuthorizationContextType {
  userId: string | null;
  name: string | null;
  username: string | null;
  roles: string[];
  isGuest: boolean;
  isRequestor: boolean;
  isSME: boolean;
  isCoordinator: boolean;
  isEndorser: boolean;
  isAdmin: boolean;
  setRole: (role: UserRole, value: boolean) => void;
  signOut: () => void;
}

export const AuthorizationContext = createContext<AuthorizationContextType>({
  userId: null,
  name: null,
  username: null,
  roles: [],
  isGuest: true,
  isRequestor: false,
  isSME: false,
  isCoordinator: false,
  isEndorser: false,
  isAdmin: false,
  setRole: () => {},
  signOut: () => {}
});

export const useAuthorization = () => useContext(AuthorizationContext);

export function AuthorizationProvider({ children }: PropsWithChildren) {
  const userAccountInfo: AccountInfo | null = useAccount();
  const { instance } = useMsal();

  const roles = useMemo(() => userAccountInfo?.idTokenClaims?.roles ?? [], [userAccountInfo?.idTokenClaims?.roles]);

  const [isGuest, setIsGuest] = useState<boolean>(true);
  const [isRequestor, setIsRequestor] = useState<boolean>(false);
  const [isSME, setIsSME] = useState<boolean>(false);
  const [isCoordinator, setIsCoordinator] = useState<boolean>(false);
  const [isEndorser, setIsEndorser] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsGuest(roles.includes(UserRole.Guest));
    setIsRequestor(roles.length === 1 && roles.includes(UserRole.Guest));
    setIsSME(roles.includes(UserRole.TabOwner));
    setIsCoordinator(roles.includes(UserRole.Coordinator));
    setIsEndorser(roles.includes(UserRole.Endorser));
    setIsAdmin(roles.includes(UserRole.Administrator));
  }, [roles]);

  const setRole = (role: UserRole, value: boolean) => {
    switch (role) {
      case UserRole.Administrator:
        setIsAdmin(value);
        break;
      case UserRole.TabOwner:
        setIsSME(value);
        break;
      case UserRole.Coordinator:
        setIsCoordinator(value);
        break;
      case UserRole.Endorser:
        setIsEndorser(value);
        break;
      default:
        break;
    }
  };

  const signOut = () => {
    const logoutRequest = {
      account: userAccountInfo
    };
    instance.logoutRedirect(logoutRequest);
  };

  const contextValue = {
    userId: userAccountInfo?.idTokenClaims?.oid ?? null,
    name: userAccountInfo?.name ?? null,
    username: userAccountInfo?.username ?? null,
    roles,
    isGuest,
    isRequestor,
    isSME,
    isCoordinator,
    isEndorser,
    isAdmin,
    setRole,
    signOut
  };

  return <AuthorizationContext.Provider value={contextValue}>{children}</AuthorizationContext.Provider>;
}
