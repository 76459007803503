import { Box, Button, Stack, Typography } from "@mui/material";

import { EmptyTrackingComments } from "./EmptyTrackingComments";
import { SelectTrackingComments } from "./SelectTrackingComments";
import { useState } from "react";
import { useARContext, useDisciplineTabContext } from "@/context";
import { ExistingTrackingComments } from "./ExistingTrackingComments";
import { ApprovalRequestDisciplineStatus, ApprovalRequestStatus } from "@/interfaces";

export function TrackingComments() {
  const { trackingComments, currentARDiscipline } = useDisciplineTabContext();
  const { approvalRequest, userIsSMEOfDisciplineOrAdmin } = useARContext();
  const [showSelect, setShowSelect] = useState(false);

  const noTrackingCommentsSelected = trackingComments.filter(({ isChecked }) => isChecked).length === 0;

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: "row",
          height: "3rem",
          alignItems: "center"
        }}
      >
        <Typography sx={{ fontSize: "1.25rem" }}>Tracking Comments</Typography>
        {!noTrackingCommentsSelected ? (
          <Button
            sx={{ height: "1rem", marginLeft: "2rem" }}
            onClick={() => {
              setShowSelect(true);
            }}
            disabled={
              !userIsSMEOfDisciplineOrAdmin ||
              approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
              currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
            }
          >
            Select
          </Button>
        ) : null}
      </Stack>
      <Stack style={{ justifyContent: "center" }} spacing="2rem">
        {noTrackingCommentsSelected ? (
          <EmptyTrackingComments
            handleSelectTrackingOnClicked={() => {
              setShowSelect(true);
            }}
          />
        ) : (
          <ExistingTrackingComments />
        )}
        <SelectTrackingComments open={showSelect} setOpen={setShowSelect} />
      </Stack>
    </Box>
  );
}
