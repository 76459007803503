import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, ClickAwayListener, Divider, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Fade from "@mui/material/Fade";

import { NotificationType } from "@/interfaces";
import { colours } from "@/theme/colour";
import { APPROVAL_REQUEST_PATH } from "@/constants";
import { useNotifications } from "@/hooks";
import { UnreadBadge } from "../UnreadBadge";
import { NotificationCard } from "./NotificationCard";

export function NotificationPopper() {
  const navigate = useNavigate();

  const { notifications } = useNotifications(undefined, false, undefined, [
    NotificationType.Actionable,
    NotificationType.InApp
  ]);

  const unreadNotificationCount = notifications.filter((x) => !x.isRead).length;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (arID: string) => {
    const path = generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: arID });
    navigate(path);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <IconButton
          data-testid="Notifications-popup-icon"
          size="large"
          edge="end"
          aria-label="notifications"
          aria-haspopup="true"
          style={{ color: colours.iconBackground }}
          onClick={handleClick}
        >
          <UnreadBadge
            visible={unreadNotificationCount > 0}
            badgeContent={unreadNotificationCount}
            anchorOverride={{ horizontal: "right" }}
          >
            <NotificationsIcon />
          </UnreadBadge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          sx={{ mt: 1, p: 0, maxHeight: 650 }}
          MenuListProps={{ sx: { p: 0 } }}
        >
          {notifications.length > 0 ? (
            <Stack>
              <Stack direction="row" p={2} justifyContent="space-between">
                <Typography>All notifications</Typography>
                <ArrowDropDownIcon />
                <MoreVertIcon sx={{ ml: "auto" }} />
              </Stack>
              <Divider />
              {notifications.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    handleClose();
                    handleMenuClick(item.approvalRequestId);
                  }}
                  sx={{ p: "1.5rem" }}
                >
                  <NotificationCard {...item} />
                </MenuItem>
              ))}
            </Stack>
          ) : (
            <Stack direction="row" px={2} py={2} width={400}>
              <Typography>No Notifications Available</Typography>
            </Stack>
          )}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
}
