import { FormGroup, styled } from "@mui/material";
import {
  GridActionsCellItem,
  GridEditSingleSelectCell,
  GridEventListener,
  GridRenderEditCellParams,
  useGridApiContext,
  useGridApiEventHandler
} from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { ApprovalRequestWorkCategoryItem } from "@/interfaces";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { useLayoutEffect, useRef, KeyboardEvent, useState, ChangeEvent, FocusEvent, useEffect } from "react";
import { NumericTextField } from "@/components/fields";

const _MAX_LENGTH = 7;
const _UNIT_AREA = "ha";

export const InlineSingleSelectComponent = ({ ...props }) => {
  const ref = useRef<HTMLElement>();
  const { gridRenderEditCellParams } = props;
  const { hasFocus, error } = gridRenderEditCellParams;

  useLayoutEffect(() => {
    if (hasFocus) ref.current?.focus();
  }, [hasFocus]);

  return (
    <StyledTooltip open={error?.length > 0} title={error} placement="right">
      <FormGroup sx={{ width: "100%" }}>
        <GridEditSingleSelectCell required ref={ref} {...gridRenderEditCellParams} {...props} />
      </FormGroup>
    </StyledTooltip>
  );
};

export const InlineNumericInputComponent = (props: GridRenderEditCellParams<ApprovalRequestWorkCategoryItem>) => {
  const { id, field, error, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = useRef<HTMLElement>();
  const [currentValue, setCurrentValue] = useState(props.row.answer.toString());
  useLayoutEffect(() => {
    if (hasFocus)
      setTimeout(() => {
        ref.current?.focus();
      }, 150);
  }, [hasFocus]);
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value);
  };

  const handleOnBlur = async (event: FocusEvent<HTMLInputElement>) => {
    let processedValue = "";
    if (event.target.value !== "") {
      const floatValue = parseFloat(event.target.value);
      processedValue = floatValue === 0 ? "0.0" : floatValue.toString();
      setCurrentValue(processedValue);
    }

    await handleSaveAndStopEditField(processedValue);
  };

  // To tackle press enter key able to bypass the field validation issue
  const handleOnKeyDown = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== "Enter") return;

    await handleSaveAndStopEditField(currentValue);
  };

  const handleSaveAndStopEditField = async (value: string) => {
    // This will force the grid validate the value during on key down event
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: value
    });
  };

  return (
    <StyledTooltip open={error?.length > 0} title={error}>
      <FormGroup sx={{ width: "100%" }}>
        <NumericTextField
          unit={_UNIT_AREA}
          inputRef={ref}
          maxLength={_MAX_LENGTH}
          onBlur={handleOnBlur}
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
          defaultText={currentValue}
          isReadOnly={false}
        ></NumericTextField>
      </FormGroup>
    </StyledTooltip>
  );
};

export const InlineActionInputComponent = ({ ...props }) => {
  const [rowHovered, setRowHovered] = useState(false);
  const apiRef = useGridApiContext();
  const { gridRenderEditCellParams } = props;
  const { id: currentRowId, row } = gridRenderEditCellParams;

  useEffect(() => {
    if (apiRef.current.getRowElement(currentRowId)?.matches(":hover")) setRowHovered(true);
  }, [apiRef, currentRowId]);

  const handleRowEnter: GridEventListener<"rowMouseEnter"> = ({ id }) => id === currentRowId && setRowHovered(true);
  const handleRowLeave: GridEventListener<"rowMouseLeave"> = ({ id }) => id === currentRowId && setRowHovered(false);

  useGridApiEventHandler(apiRef, "rowMouseEnter", handleRowEnter);
  useGridApiEventHandler(apiRef, "rowMouseLeave", handleRowLeave);

  if (row.isMandatory || !rowHovered) return null;

  return <GridActionsCellItem icon={<CloseIcon />} label="Delete" {...props} />;
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}));
