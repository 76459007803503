import { apiClient } from "@/api/client";
import {
  ARDisciplineByARIdRequest,
  ApprovalRequestDisciplineStatus,
  DisciplineTrackingComment,
  PaginationRequest,
  ARDiscipline,
  SMEAssignedApprovalRequestList,
  ApprovalRequestStatus,
  DisciplineAuthorisationComment,
  InternalDependency
} from "@/interfaces";
import { AuthoriseDisciplineRequest } from "@/interfaces/api/requests/AuthoriseDiscipline";
import { DisciplineCommentsRequest } from "@/interfaces/api/requests/DisciplineComments";

const basePath = "/approvalRequestDiscipline";

export const getSMEAssignedApprovalRequests = (
  params: {
    disciplineStatus: ApprovalRequestDisciplineStatus;
    approvalRequestStatus: ApprovalRequestStatus;
  } & PaginationRequest
) =>
  apiClient
    .get<SMEAssignedApprovalRequestList>(`${basePath}/assigned/paginated`, {
      params: params
    })
    .then((response) => response.data);

export const getDisciplinesByApprovalRequestId = (request: ARDisciplineByARIdRequest) =>
  apiClient
    .get<ARDiscipline[]>(`${basePath}`, {
      params: { ...request }
    })
    .then((response) => response.data);

export const getDisciplineTrackingComments = (approvalRequestDisciplineId: string) =>
  apiClient
    .get<DisciplineTrackingComment[]>(`${basePath}/${approvalRequestDisciplineId}/tracking-comments`)
    .then((response) => response.data);

export const updateDisciplineTrackingComment = (request: DisciplineTrackingComment) =>
  apiClient
    .put<DisciplineTrackingComment>(
      `${basePath}/${request.approvalRequestDisciplineId}/tracking-comments/${request.id}`,
      request
    )
    .then((response) => response.data);

export const getDisciplineAuthorisationComments = (approvalRequestDisciplineId: string) =>
  apiClient
    .get<DisciplineAuthorisationComment[]>(`${basePath}/${approvalRequestDisciplineId}/authorisation-comments`)
    .then((response) => response.data);

export const getInternalDependencies = (approvalRequestId: string) =>
  apiClient
    .get<InternalDependency[]>(`${basePath}/${approvalRequestId}/tracking-comments-dependency`)
    .then((response) => response.data);

export const updateDisciplineComments = (request: DisciplineCommentsRequest) =>
  apiClient.put(`${basePath}/${request.approvalRequestDisciplineId}/comments`, request).then((response) => response);

export const authoriseDiscipline = (request: AuthoriseDisciplineRequest) =>
  apiClient.put(`${basePath}/${request.approvalRequestDisciplineId}/authorise`, request).then((response) => response);
