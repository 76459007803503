import { useCallback, useMemo } from "react";
import { useNotifications } from "@/hooks";
import { NotificationType } from "@/interfaces";

export const useUnreadIndicator = <T extends { id: string }>(approvalRequestList: T[]) => {
  const { notifications } = useNotifications(false, false, undefined, [NotificationType.Unread]);

  const arIds = useMemo(() => approvalRequestList.map((ar) => ar.id), [approvalRequestList]);

  const unreadARIds = useMemo(
    () =>
      notifications.reduce((unread: string[], notification) => {
        if (arIds.includes(notification.approvalRequestId)) {
          unread.push(notification.approvalRequestId);
        }
        return unread;
      }, []),
    [notifications, arIds]
  );

  const isUnread = useCallback((approvalRequestId: string) => unreadARIds.includes(approvalRequestId), [unreadARIds]);

  return { isUnread };
};
