import { AR_BASIC_DETAILS_LOCATORS, CHANGE_DETAILS_CLASS_NAME, HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";
import { ApprovalRequestStatus } from "@/interfaces";
import { colours } from "@/theme/colour";
import { dateFormatter } from "@/utils";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { SetStateAction } from "jotai";
import { Dispatch, useEffect, useMemo, useState } from "react";

interface PastArData {
  version: number;
  fieldName: "Title" | "Hub" | "Site" | "BusinessUnit" | "Project" | "Description" | "CostCode" | "RequiredBy";
  fieldReferenceId?: string; // Cater for QuestionAnswer | WorkCategory
  originalValue: string;
  newValue: string;
  updated: Date;
  updatedBy: string;
}

interface PastArVersion {
  version: number;
  status: ApprovalRequestStatus;
}

interface ARDetailsChangesProps {
  correspondArDetailHighlightKey: string;
  updateCorrespondArDetailFieldKey: Dispatch<SetStateAction<string>>;
}

const ChangesColumnConfig: Record<PastArData["fieldName"], { key: string; label: string; showComparison: boolean }> = {
  Title: { key: AR_BASIC_DETAILS_LOCATORS.TITLE_FIELD, label: "Title", showComparison: true },
  Hub: { key: AR_BASIC_DETAILS_LOCATORS.HUB_FIELD, label: "Hub", showComparison: true },
  Site: { key: AR_BASIC_DETAILS_LOCATORS.SITE_FIELD, label: "Site", showComparison: true },
  BusinessUnit: { key: AR_BASIC_DETAILS_LOCATORS.BUSINESS_UNIT_FIELD, label: "Business Unit", showComparison: true },
  Project: { key: AR_BASIC_DETAILS_LOCATORS.PROJECT_FIELD, label: "Project", showComparison: true },
  Description: { key: AR_BASIC_DETAILS_LOCATORS.DESCRIPTION_FIELD, label: "Description", showComparison: false },
  CostCode: { key: AR_BASIC_DETAILS_LOCATORS.COST_CODE_FIELD, label: "Cost Code", showComparison: true },
  RequiredBy: { key: AR_BASIC_DETAILS_LOCATORS.REQUIRED_BY_DATE_FIELD, label: "Required By", showComparison: true }
};

//Temporary Data. Will be removed when Hook built
const dummyPastArs: PastArData[] = [
  {
    version: 0.0,
    fieldName: "Site",
    originalValue: "Original Value",
    newValue: "Greater Brockman 1",
    updated: new Date("2024-07-16 16:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.0,
    fieldName: "CostCode",
    originalValue: "Original Value",
    newValue: "Aloha",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.0,
    fieldName: "Description",
    originalValue: "Original Value",
    newValue: "Aloha",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.1,
    fieldName: "Site",
    originalValue: "Original Value",
    newValue: "Greater Brockman 1",
    updated: new Date("2024-07-16 16:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.1,
    fieldName: "CostCode",
    originalValue: "Original Value",
    newValue: "Aloha",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.1,
    fieldName: "Description",
    originalValue: "Original Value",
    newValue: "Aloha",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.1,
    fieldName: "RequiredBy",
    originalValue: "Original Value",
    newValue: dayjs().toString(),
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.2,
    fieldName: "Site",
    originalValue: "Original Value",
    newValue: "Greater Brockman 1",
    updated: new Date("2024-07-16 16:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.2,
    fieldName: "CostCode",
    originalValue: "Original Value",
    newValue: "Aloha",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.2,
    fieldName: "Description",
    originalValue: "Original Value",
    newValue: "Aloha",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.2,
    fieldName: "Title",
    originalValue: "Original Value",
    newValue: "This is New Title",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.2,
    fieldName: "Project",
    originalValue: "Original Value",
    newValue: "This is New Project",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.3,
    fieldName: "Site",
    originalValue: "Original Value",
    newValue: "Greater Brockman 1",
    updated: new Date("2024-07-16 16:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.3,
    fieldName: "CostCode",
    originalValue: "Original Value",
    newValue: "Aloha",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.3,
    fieldName: "Description",
    originalValue: "Original Value",
    newValue: "Aloha",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.3,
    fieldName: "Hub",
    originalValue: "Original Value",
    newValue: "This is New Hub",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.3,
    fieldName: "BusinessUnit",
    originalValue: "Original Value",
    newValue: "This is New Business Unit",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  },
  {
    version: 0.3,
    fieldName: "Title",
    originalValue: "Original Value",
    newValue: "This is New Title",
    updated: new Date("2024-07-16 13:58:30.2366969 +08:00"),
    updatedBy: "Ahamat, Farid (IST-ENDAVA)"
  }
];

const dummyArVersions: PastArVersion[] = [
  {
    version: 0.1,
    status: ApprovalRequestStatus.Distributed
  },
  {
    version: 0.2,
    status: ApprovalRequestStatus.Submitted
  },
  {
    version: 0.3,
    status: ApprovalRequestStatus.Submitted
  }
];

export function ARDetailsChanges({
  correspondArDetailHighlightKey,
  updateCorrespondArDetailFieldKey
}: ARDetailsChangesProps) {
  const [selectedVersion, setSelectedVersion] = useState<PastArVersion>();
  const dateTimeFormat: string = "h:mm A, D MMMM YYYY";
  const versionSelectLabel: string = "Comparing changes to";

  const versionedArDetailChanges = useMemo(
    () => dummyPastArs.filter((ar) => ar.version === selectedVersion?.version),
    [selectedVersion]
  );

  const onSelectVersion = (event: SelectChangeEvent) => {
    setSelectedVersion(dummyArVersions[Number(event.target.value)]);
    updateCorrespondArDetailFieldKey("");
  };

  useEffect(() => {
    if (correspondArDetailHighlightKey === "") return;
    const highlightedFields =
      document.getElementsByClassName(HIGHLIGHT_FIELD_CLASS_NAMES.BACKGROUND) ||
      document.getElementsByClassName(HIGHLIGHT_FIELD_CLASS_NAMES.BORDER);
    if (highlightedFields.length > 0) {
      highlightedFields[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, [correspondArDetailHighlightKey]);

  return (
    <Box bgcolor={colours.sidePanel} sx={{ height: "100%", p: "1rem" }} overflow="auto">
      <FormControl fullWidth sx={{ marginTop: "1rem" }}>
        <InputLabel>{versionSelectLabel}</InputLabel>
        <Select onChange={onSelectVersion} label={versionSelectLabel}>
          {dummyArVersions.map((ar, idx) => (
            <MenuItem value={idx}>
              Version {ar.version} - {ApprovalRequestStatus[ar.status]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MenuList>
        {versionedArDetailChanges.map((ar) => (
          <Card variant="outlined" sx={{ marginTop: "1rem" }}>
            <CardActionArea
              className={
                correspondArDetailHighlightKey === ChangesColumnConfig[ar.fieldName].key
                  ? CHANGE_DETAILS_CLASS_NAME
                  : ""
              }
              onClick={() => updateCorrespondArDetailFieldKey(ChangesColumnConfig[ar.fieldName].key)}
            >
              <CardContent>
                <Typography sx={{ fontWeight: "bold", fontSize: "h6.fontSize" }} gutterBottom>
                  {ChangesColumnConfig[ar.fieldName].label}
                </Typography>
                {ChangesColumnConfig[ar.fieldName].showComparison && (
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Typography color="#00000099" gutterBottom>
                        New Value
                      </Typography>
                      <Typography sx={{ fontWeight: "500" }} gutterBottom>
                        {ar.newValue}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography color="#00000099" gutterBottom>
                        Original Value
                      </Typography>
                      <Typography sx={{ fontWeight: "500" }} gutterBottom>
                        {ar.originalValue}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                <Typography color="#00000099" gutterBottom noWrap>
                  Modified • {dateFormatter(ar.updated, dateTimeFormat)} • {ar.updatedBy}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </MenuList>
    </Box>
  );
}
