import { MANDATORY_FIELD } from "@/constants";
import { LAMSRole } from "@/interfaces";
import { object, string } from "yup";
import * as yup from "yup";

export const userHubRoleSchema = object({
  [LAMSRole.Endorser]: string().trim().required(MANDATORY_FIELD.DEFAULT),
  [LAMSRole.Coordinator]: string().trim().required(MANDATORY_FIELD.DEFAULT)
});

export const addDisciplineUserMatrixSchema = yup.object({
  siteId: yup.string().trim().required(MANDATORY_FIELD.DEFAULT),
  businessUnitId: yup.string().trim().required(MANDATORY_FIELD.DEFAULT),
  disciplineId: yup.string().trim().required(MANDATORY_FIELD.DEFAULT),
  primaryApproverId: yup.string().trim().required(MANDATORY_FIELD.DEFAULT),
  secondaryApproverId: yup.string().trim().optional()
});
