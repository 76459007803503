import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";

import { useAuthorization, useARContext } from "@/context";
import { MapRender } from "./MapRender";
import { MapUpload } from "./MapUpload";
import { useGetApprovalRequestMap } from "@/hooks/api/ApprovalRequestHooks";

export function MapTab() {
  const { isRequestor } = useAuthorization();
  const { approvalRequestId } = useARContext();
  const [isLoading, setIsLoading] = useState(true);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isMapExist, setIsMapExist] = useState<boolean>();
  const { data: arMap, isFetched: arMapFetched } = useGetApprovalRequestMap(approvalRequestId);

  useEffect(() => {
    if (arMapFetched) {
      setIsMapExist(arMap && arMap.id !== undefined);
    }
    setIsLoading(false);
  }, [arMap, arMapFetched]);

  const showLoader = isLoading || (!mapLoaded && isMapExist);
  return (
    <Grid data-testid="map-tab-display" id="ar-map-grid" container direction={"row"} sx={{ height: "100%" }}>
      <Grid item flex={1}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          {showLoader && <CircularProgress className="circularProgress" />}
          {isMapExist && <MapRender mapLoaded={mapLoaded} setMapLoaded={setMapLoaded} />}
          {isMapExist === false && isRequestor && <MapUpload setIsMapExist={setIsMapExist} />}
        </Box>
      </Grid>
    </Grid>
  );
}
