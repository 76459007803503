import { useEffect, useMemo, Fragment, InputHTMLAttributes } from "react";
import { useAtom, useSetAtom } from "jotai";
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  Typography
} from "@mui/material";
import { FieldError, FieldErrorsImpl, Merge, useFormContext } from "react-hook-form";

import { EMPTY_GUID } from "@/constants";
import { ARFormSection, ARFormFields, QAQuestionAnswer, QAAnswerItem } from "@/interfaces";
import { ChangeType } from "@/types";
import { mapToQAQuestionAnswers } from "@/utils";
import { approvalRequestQuestionAnswerListAtom, isAllQuestionAnsweredAtom } from "@/stores";
import { useARContext } from "@/context";
import { useQuestionAndAnswers } from "@/hooks";
import { ARDivider } from "@/components";
import { withARAwareReadOnly } from "@/components/hocs";
import { NumericTextField } from "@/components/fields";

// This complicated type is taken from the generated type from hook-form.
// It substitutes the actual object structure from the stored data type.
type QAValidationErrors = Merge<
  FieldError,
  (Merge<FieldError, FieldErrorsImpl<ChangeType<QAQuestionAnswer, string>>> | undefined)[]
>;

const _MAX_LENGTH = 7;
const _UNIT_AREA = "ha";
const _COMPONENT_NAME = {
  NUMERIC_TEXTFIELD: "NumericTextField"
};

const titleStyle: SxProps = {
  fontSize: "1.2em",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
};

const spacingNormal: SxProps = {
  ml: 2
};

const spacingLarge: SxProps = {
  ml: 5
};

const subQuestionStyle: SxProps = {
  maxWidth: 120,
  ml: 6
};

const disabledTypeStyles: SxProps = {
  color: "rgba(0, 0, 0, 0.38)"
};

const flexItemStyles: SxProps = {
  display: "flex",
  p: 1
};

interface ARQuestionAnswersProps {
  errors?: QAValidationErrors;
  readOnly?: boolean;
  resetError?: () => void;
  formHelperErrorClass?: string;
}

interface NestedQuestion extends QAQuestionAnswer {
  subQuestion: QAQuestionAnswer | null;
}

export function ARQuestionAnswers({ readOnly, errors, resetError, formHelperErrorClass }: ARQuestionAnswersProps) {
  const { approvalRequestId, approvalRequestType } = useARContext();
  const [answers, setAnswers] = useAtom(approvalRequestQuestionAnswerListAtom);
  const setIsAllQuestionsAnswered = useSetAtom(isAllQuestionAnsweredAtom);
  const { reset, setValue, getValues } = useFormContext<ARFormFields>();

  const {
    data: { questions },
    isFetched: qaFetched
  } = useQuestionAndAnswers(
    {
      approvalRequestId,
      approvalRequestTypeId: approvalRequestType.id,
      isEnabled: true
    },
    ({ questions, answers }) => {
      setAnswers(answers);
      reset({
        ...getValues(),
        approvalRequestAnswers: mapToQAQuestionAnswers(questions, answers)
      });
    }
  );

  // Initialising all answers and mapping them to question ids for easier consumption.
  const memoizedAnswers = useMemo(() => {
    // Build answers from default values provided by reference data.
    const defaultAnswers = questions.map(({ id, defaultValue }) => ({
      questionId: id,
      answer: defaultValue ?? ""
    }));

    // Map answers to their question ids.
    const indexedAnswers = answers.reduce((indexedAnswers: Record<string, QAAnswerItem>, answer) => {
      indexedAnswers[answer.questionId] = answer;
      return indexedAnswers;
    }, {});

    // Override default answers with user entered answers.
    defaultAnswers.forEach(({ questionId, answer }) => {
      if (!(questionId in indexedAnswers)) {
        indexedAnswers[questionId] = { questionId, answer };
      }
    });

    return indexedAnswers;
  }, [answers, questions]);

  // Remap questions into a nested structure to define relationship between questions and sub questions.
  const memoizedQuestionAnswers = useMemo(() => {
    const mappedQuestionAnswers = questions
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .reduce((indexedQuestionAnswers: Record<string, NestedQuestion>, question, index) => {
        if (question.parentId === EMPTY_GUID) {
          const answer = memoizedAnswers[question.id].answer ?? "";
          indexedQuestionAnswers[question.id] = {
            ...question,
            answer,
            parentAnswer: null,
            subQuestion: null,
            error: errors?.[index]?.answer?.message
          };
        } else {
          const parentAnswer = memoizedAnswers[question.parentId].answer ?? null;
          indexedQuestionAnswers[question.parentId].subQuestion = {
            ...question,
            answer:
              parentAnswer === "no"
                ? question.defaultValue ?? ""
                : memoizedAnswers[question.id]?.answer ?? question.defaultValue ?? "",
            parentAnswer,
            isConditional: !parentAnswer || parentAnswer === "no",
            error: errors?.[index]?.answer?.message
          };
        }

        return indexedQuestionAnswers;
      }, {});

    return mappedQuestionAnswers;
  }, [questions, errors, memoizedAnswers]);

  // Determine if all questions are answered by incrementing a pending question counter for each eligible question.
  const allQuestionsAnswered = useMemo(() => {
    if (!qaFetched) return false;
    let pendingAnswerCount = 0;
    Object.values(memoizedQuestionAnswers).forEach((question) => {
      if (!question.isConditional && !question.isDisplayOnly) {
        if (memoizedAnswers[question.id].answer === "") {
          pendingAnswerCount++;
        }
      }

      if (question.subQuestion && !question.subQuestion.isConditional && !question.subQuestion.isDisplayOnly) {
        if (question.subQuestion.answer === "") {
          pendingAnswerCount++;
        }
      }
    });
    return pendingAnswerCount === 0;
  }, [qaFetched, memoizedAnswers, memoizedQuestionAnswers]);

  const handleQuestionAnswerChange = (targetValue: string, answeredQuestion: QAQuestionAnswer) => {
    resetError?.();

    // Flatten nested structure with parent answer retained to update form data.
    const updatedAnswers = Object.values(memoizedQuestionAnswers).reduce(
      (flattenedAnswers: Record<string, QAQuestionAnswer>, questionAnswer) => {
        flattenedAnswers[questionAnswer.id] = { ...questionAnswer };

        if (questionAnswer.id === answeredQuestion.id) {
          flattenedAnswers[questionAnswer.id] = {
            ...answeredQuestion,
            answer: targetValue
          };
        }

        const subQuestion = questionAnswer.subQuestion;
        if (subQuestion) {
          flattenedAnswers[subQuestion.id] = {
            ...subQuestion,
            answer: subQuestion.id === answeredQuestion.id ? targetValue : subQuestion.answer,
            parentAnswer: subQuestion.parentId === answeredQuestion.id ? targetValue : subQuestion.parentAnswer
          };
        }

        return flattenedAnswers;
      },
      {}
    );

    setValue("approvalRequestAnswers", Object.values(updatedAnswers), { shouldDirty: true });
    setAnswers(Object.values(updatedAnswers).map(({ id: questionId, answer }) => ({ questionId, answer })));
  };

  useEffect(() => {
    setIsAllQuestionsAnswered(allQuestionsAnswered);
  }, [allQuestionsAnswered, setIsAllQuestionsAnswered]);

  return questions.length > 0 ? (
    <>
      <ARDivider />
      <Typography sx={titleStyle} gutterBottom variant="h6">
        Questions
      </Typography>
      <br />
      <Grid container sx={spacingNormal}>
        <Grid item sx={spacingNormal}>
          <Typography>Yes</Typography>
        </Grid>
        <Grid item sx={spacingLarge}>
          <Typography>No</Typography>
        </Grid>
        {Object.values(memoizedQuestionAnswers).map(({ subQuestion, ...arQuestion }, index) => {
          return (
            <Fragment key={`${arQuestion.id}-${index}`}>
              <Grid item xs={12}>
                <RadioGroup
                  sx={{ display: "flex", flexWrap: "nowrap" }}
                  row
                  value={arQuestion.answer}
                  id={`question_${arQuestion.id}`}
                  onChange={(event) => {
                    handleQuestionAnswerChange(event.target.value, arQuestion);
                  }}
                >
                  <Grid item sx={spacingNormal}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          inputProps={
                            {
                              "data-testid": `radio-question-yes-${arQuestion.id}`
                            } as InputHTMLAttributes<HTMLInputElement>
                          }
                        />
                      }
                      label=""
                      disabled={arQuestion.isDisplayOnly || readOnly}
                    />
                  </Grid>
                  <Grid item sx={spacingNormal}>
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          inputProps={
                            {
                              "data-testid": `radio-question-no-${arQuestion.id}`
                            } as InputHTMLAttributes<HTMLInputElement>
                          }
                        />
                      }
                      label=""
                      disabled={arQuestion.isDisplayOnly || readOnly}
                    />
                  </Grid>
                  <Grid item sx={flexItemStyles}>
                    <Typography
                      variant="body1"
                      sx={{
                        ...(arQuestion.isDisplayOnly ? disabledTypeStyles : {})
                      }}
                    >
                      {arQuestion.question}
                    </Typography>
                  </Grid>
                  {subQuestion && subQuestion.displayLevel === 2 ? (
                    subQuestion.componentName === _COMPONENT_NAME.NUMERIC_TEXTFIELD ? (
                      <Grid item sx={{ ...flexItemStyles, maxWidth: "60%" }}>
                        <Stack direction="column">
                          <NumericTextField
                            key={`subQuestion_${subQuestion.id}`}
                            inputProps={
                              {
                                "data-testid": `numericTextField-sub-question-${arQuestion.id}`
                              } as InputHTMLAttributes<HTMLInputElement>
                            }
                            label={subQuestion.question}
                            id={`subQuestion_${subQuestion.id}`}
                            maxLength={_MAX_LENGTH}
                            unit={_UNIT_AREA}
                            defaultText={subQuestion.answer}
                            isReadOnly={readOnly || subQuestion.isConditional}
                            sx={subQuestionStyle}
                            onBlurSubmit={(value) => {
                              handleQuestionAnswerChange(value, subQuestion);
                            }}
                          />
                          <FormHelperText
                            error
                            sx={{ marginBottom: 1, ml: 6 }}
                            className={subQuestion.error ? formHelperErrorClass : undefined}
                          >
                            {subQuestion.error ?? " "}
                          </FormHelperText>
                          {subQuestion.helpText !== "" ? (
                            <Typography
                              variant="body2"
                              sx={{ ...flexItemStyles, ml: 1, fontSize: 12, flexWrap: "wrap" }}
                            >
                              {subQuestion.helpText}
                            </Typography>
                          ) : null}
                        </Stack>
                      </Grid>
                    ) : null
                  ) : null}
                </RadioGroup>
              </Grid>
              {subQuestion &&
              subQuestion.displayLevel === 1 &&
              (!subQuestion.isConditional || subQuestion.answer !== "") ? (
                <Grow key={subQuestion.id} in={true} timeout={500} mountOnEnter unmountOnExit>
                  <Grid key={`${index}-1`} item xs={12}>
                    <RadioGroup
                      row
                      value={subQuestion.answer}
                      id={`subQuestion_${arQuestion.id}`}
                      onChange={(event) => {
                        handleQuestionAnswerChange(event.target.value, subQuestion);
                      }}
                    >
                      <Grid item sx={spacingNormal}>
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              inputProps={
                                {
                                  "data-testid": `radio-sub-question-yes-${arQuestion.id}`
                                } as InputHTMLAttributes<HTMLInputElement>
                              }
                            />
                          }
                          label=""
                          disabled={subQuestion.isDisplayOnly || readOnly}
                        />
                      </Grid>
                      <Grid item sx={spacingNormal}>
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              inputProps={
                                {
                                  "data-testid": `radio-sub-question-no-${arQuestion.id}`
                                } as InputHTMLAttributes<HTMLInputElement>
                              }
                            />
                          }
                          label=""
                          disabled={subQuestion.isDisplayOnly || readOnly}
                        />
                      </Grid>
                      <Grid item sx={flexItemStyles}>
                        <Typography>{subQuestion.question}</Typography>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Grow>
              ) : null}
            </Fragment>
          );
        })}
      </Grid>
    </>
  ) : null;
}

export const ARAwareReadOnlyQuestionAnswers = withARAwareReadOnly(ARFormSection.Question, ARQuestionAnswers);
