import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { ARFormSection } from "@/interfaces";
import { withARAwareReadOnly } from "@/components/hocs";
import { AppFormInputProps } from "./AppFormProps";
import { HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";

export const AppFormInput = ({
  name,
  control,
  label,
  error,
  showError = true,
  "data-testid": dataTestId,
  disabled,
  multiline,
  rows,
  maxRows,
  inputProps,
  size,
  margin,
  onChangeHandler,
  readOnly,
  resetError,
  maxLength,
  formHelperErrorClass,
  required,
  isHighlight
}: AppFormInputProps) => {
  return (
    <FormControl fullWidth size={size}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            required={required}
            error={!!error}
            onChange={(event) => {
              onChange(event);
              onChangeHandler?.(event, name);
              resetError?.();
            }}
            value={value}
            fullWidth
            label={label}
            variant="outlined"
            multiline={multiline}
            rows={multiline ? rows : undefined}
            inputProps={inputProps}
            maxRows={maxRows}
            data-testid={dataTestId}
            size={size}
            margin={margin}
            disabled={disabled}
            InputProps={{
              inputProps: {
                maxLength,
                readOnly,
                "data-testid": `${dataTestId}-textField`
              }
            }}
            className={isHighlight ? HIGHLIGHT_FIELD_CLASS_NAMES.BACKGROUND : ""}
          />
        )}
      />
      <FormHelperText error sx={{ marginBottom: 1 }} className={error ? formHelperErrorClass : undefined}>
        {error?.message && showError ? error.message : " "}
      </FormHelperText>
    </FormControl>
  );
};

export const ARAwareReadOnlyAppFormInput = withARAwareReadOnly(ARFormSection.BasicForm, AppFormInput);
