import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Grid } from "@mui/material";

import { isAdminModeAtom } from "@/stores";
import { APP_BAR_COLOR, FILE_NAMES, PATH, TITLE } from "@/constants";
import { colours } from "@/theme/colour";
import { NotificationPopper } from "@/components/notifications/NotificationPopper";
import { ProfilePopper } from "./ProfilePopper";

const StyledAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export function Navbar() {
  const navigate = useNavigate();
  const isAdminMode = useAtomValue(isAdminModeAtom);

  const backgroundColorValue = isAdminMode ? APP_BAR_COLOR.ADMIN : APP_BAR_COLOR.ALL;

  return (
    <Box id="top-navigation-bar" sx={{ height: "4rem" }}>
      <CssBaseline />
      <StyledAppBar position="fixed" sx={{ backgroundColor: backgroundColorValue, zIndex: 9999 }}>
        <Toolbar>
          <IconButton
            data-testid="HomePageButtonId"
            size="large"
            edge="start"
            aria-label="home"
            style={{ color: colours.iconBackground }}
            sx={{ mr: 2 }}
            onClick={() => {
              navigate(PATH.BASE);
            }}
          >
            <img src={`${import.meta.env.REACT_APP_AUTH_REDIRECT_URI}/images/${FILE_NAMES.APP_BAR}`} alt="Rio Tinto" />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: {
                xs: "none",
                sm: "block",
                width: "20em"
              }
            }}
            data-testid="NavbarTitleId"
          >
            {isAdminMode ? TITLE.AdminTitle : TITLE.AppTitle}
          </Typography>
          <Grid
            display={"flex"}
            flexDirection={"row"}
            container
            justifyContent={"flex-end"}
            alignItems="center"
            columnSpacing={2}
          >
            {isAdminMode ? (
              <Grid item>
                <IconButton
                  sx={{ zIndex: 2 }}
                  data-testid="AdminConfigButtonId"
                  size="medium"
                  edge="start"
                  aria-label="settings"
                  style={{ color: colours.iconBackground }}
                  onClick={() => {
                    navigate(PATH.ADMIN_SETTINGS);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Grid>
            ) : null}
            <Grid item>
              <NotificationPopper />
            </Grid>
            <Grid item>
              <ProfilePopper />
            </Grid>
          </Grid>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}
