import { DisciplineAuthorisationCommentValue, IOption } from "@/interfaces";
import { MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";

interface ConfigurableSelectProps extends SelectProps {
  configuration: DisciplineAuthorisationCommentValue;
  onUpdate: (update: DisciplineAuthorisationCommentValue) => void;
  options: IOption[];
}
export function ConfigurableSelect({ configuration, onUpdate, options, ...selectProps }: ConfigurableSelectProps) {
  const onChangeHandler = (event: SelectChangeEvent) => {
    onUpdate({
      ...configuration,
      value: event.target.value
    });
  };

  return (
    <Select
      value={configuration.value}
      onChange={(event) => {
        onChangeHandler(event as SelectChangeEvent);
      }}
      size="small"
      fullWidth={true}
      {...selectProps}
    >
      {options.map(({ id, value }, index) => (
        <MenuItem key={`${id}-${index}`} value={id}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
}
