import {
  createRef,
  useState,
  KeyboardEvent,
  Dispatch,
  SetStateAction,
  MouseEvent,
  useEffect,
  useMemo,
  MouseEventHandler,
  useCallback
} from "react";
import { Editor } from "@tiptap/core/dist/packages/core/src/Editor";
import { Stack } from "@mui/system";
import {
  Box,
  Button,
  Card,
  Collapse,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";

import { ApprovalRequestReturnStatus, Commentary } from "@/interfaces";
import { colours } from "@/theme/colour";
import { MatchCaseIcon } from "@/assets/icons";
import { formatHumanizeDate } from "@/utils";
import { useAddCommentary, useUpdateCommentary, useCommentaryDecideReturnRequest } from "@/hooks";
import { UserAvatar } from "@/components/navigation";
import { RichTextEditor, RichTextEditorRef } from "@/components/rich-text-editor/RichTextEditor";
import { ExpandToggle } from "@/components/shared/ExpandToggle";
import { useARContext, useAuthorization } from "@/context";
import { MoreVert } from "@mui/icons-material";
import { CommentaryType } from "@/interfaces/api/responses/Commentary";

interface CommentProps {
  commentary: Commentary;
  open: string;
  setOpen: Dispatch<SetStateAction<string>>;
  setSelectedCommentaryForDelete: Dispatch<SetStateAction<Commentary | null>>;
  editingCommentId: string | null;
  setEditingCommentaryId: Dispatch<SetStateAction<CommentProps["editingCommentId"]>>;
}

interface CommentaryMenuItemConfig {
  name: string;
  key: string;
  onClick: MouseEventHandler;
}

enum COMMENTARY_MENU_ITEMS {
  Edit = "Edit",
  Delete = "Delete",
  Accept = "Accept",
  Decline = "Decline"
}

export function Comment({
  commentary,
  open,
  setOpen,
  setSelectedCommentaryForDelete,
  setEditingCommentaryId,
  editingCommentId
}: CommentProps) {
  const theme = useTheme();
  const { userId, name } = useAuthorization();
  const [replyBoxIsActive, setReplyBoxIsActive] = useState(false);
  const [saveReplyButtonDisabled, setSaveReplyButtonDisabled] = useState(false);
  const [saveEditButtonDisabled, setSaveEditButtonDisabled] = useState(true);
  const [hideMenuBar, setHideMenuBar] = useState(true);
  const [hideMenuBarReply, setHideMenuBarReply] = useState(true);
  const [commentHovered, setCommentHovered] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);

  const commentDisplayRef = createRef<RichTextEditorRef>();
  const replyEditorRef = createRef<RichTextEditorRef>();

  const commentReadOnly = editingCommentId !== commentary.id;
  const commentIsParent = commentary.parentCommentaryId === undefined;
  const commentActionFadeDuration = 250;

  const isEmailComment = location.hash === `#${commentary.id}`;

  useEffect(() => {
    if (isEmailComment) {
      setCommentHovered(true);
      setOpen(commentary.id);
    }
  }, [isEmailComment, commentary.id, setCommentHovered, setOpen]);

  const handleClearReplyContent = () => {
    replyEditorRef.current?.clearContent();
  };

  const handleResetEditContent = useCallback(() => {
    commentDisplayRef.current?.reset(commentary.comment);
  }, [commentDisplayRef, commentary.comment]);

  const handleCancelReply = () => {
    setReplyBoxIsActive(false);
    setOpen("");
    handleClearReplyContent();
    setHideMenuBarReply(true);
  };

  const handleCancelCommentEdit = () => {
    setEditingCommentaryId(null);
    handleResetEditContent();
    setHideMenuBar(true);
  };

  const { mutate: updateCommentary, isLoading: isUpdateLoading } = useUpdateCommentary(
    commentary.approvalRequestId,
    commentary.id
  );
  const { mutate: addCommentary, isLoading: addCommentaryIsLoading } = useAddCommentary(
    commentary.approvalRequestId,
    commentary.id,
    handleClearReplyContent
  );

  const handleEscapeKeyPressed = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      handleCancelReply();
    }
  };

  const saveReply = () => {
    const inputtedComment = replyEditorRef.current?.getContent(true);
    setSaveReplyButtonDisabled(true);
    addCommentary(inputtedComment);
  };

  const saveEdit = () => {
    const inputtedComment = commentDisplayRef.current?.getContent(true);
    setSaveEditButtonDisabled(true);
    setEditingCommentaryId(null);
    setHideMenuBar(true);
    updateCommentary(inputtedComment);
  };

  const handleEnableSaveReplyButton = (editor: Editor) => {
    setSaveReplyButtonDisabled(editor.getText().trim().length === 0);
  };

  const handleEnableSaveEditButton = (editor: Editor) => {
    setSaveEditButtonDisabled(
      commentDisplayRef.current?.getContent(true) === commentary.comment || editor.getText().trim().length === 0
    );
  };

  useEffect(() => {
    if (![null, commentary.id].includes(editingCommentId)) {
      handleResetEditContent();
      setHideMenuBar(true);
    }
  }, [commentary.id, editingCommentId, handleResetEditContent]);

  return (
    <div key={commentary.id} onMouseEnter={() => setCommentHovered(true)} onMouseLeave={() => setCommentHovered(false)}>
      <Stack direction="row" width="100%" gap="1rem" mb={1}>
        {commentIsParent && (
          <Stack
            sx={{ flexDirection: "column" }}
            visibility={commentary.replyComments.length > 0 ? "visible" : "hidden"}
          >
            <ExpandToggle
              expanded={expanded}
              toggleExpansion={() => setExpanded((old) => !old)}
              data-testid={`toggle-${commentary.id}`}
            />
          </Stack>
        )}
        <Stack sx={{ flexDirection: "column" }}>
          <Tooltip title={commentary.commentatorName}>
            <div>
              <UserAvatar
                avatarWidth={"2.625rem"}
                avatarHeight={"2.625rem"}
                fontSize={theme.typography.fontSize.toString()}
                avatarName={commentary.commentatorName}
                avatarUserId={commentary.commentatorId}
              />
            </div>
          </Tooltip>
        </Stack>
        <Stack sx={{ flexDirection: "column", flexGrow: 1 }}>
          <Grid container columnGap={2}>
            <Grid>
              <Typography noWrap sx={{ textOverflow: "ellipsis", fontSize: "0.75rem", pl: "0.5rem" }}>
                {commentary.commentatorName}
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontSize: "0.75rem" }}>
                {formatHumanizeDate(commentary.commentDate.toString())}
              </Typography>
            </Grid>
          </Grid>
          <Card
            variant="elevation"
            elevation={0}
            sx={{
              width: "100%",
              paddingX: "0rem",
              paddingY: "0rem"
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                ":hover": commentReadOnly
                  ? { background: colours.replyHover, div: { background: colours.replyHover } }
                  : null
              }}
            >
              <Stack sx={{ flexDirection: "row", gap: 2, alignItems: "flex-start" }}>
                <Card variant={commentReadOnly ? "elevation" : "outlined"} sx={{ width: "40rem", boxShadow: "0" }}>
                  <RichTextEditor
                    ref={commentDisplayRef}
                    defaultValue={commentary.comment}
                    hideMenuBar={hideMenuBar}
                    readOnly={commentReadOnly}
                    onKeyDown={handleEscapeKeyPressed}
                    onChange={handleEnableSaveEditButton}
                    hasMention={true}
                    autoFocus={true}
                    editorWrapperSX={{ height: hideMenuBar ? "auto" : "10rem", overflow: "auto" }}
                    data-testid={`input-comment-editor-${commentary.id}`}
                  />
                </Card>
                {!commentReadOnly && (
                  <>
                    <IconButton
                      sx={{ height: "3.5rem", width: "3.5rem" }}
                      data-testid={`edit-comment-toggle-${commentary.id}`}
                      onClick={() => {
                        setHideMenuBar((prev) => !prev);
                      }}
                    >
                      <SvgIcon component={MatchCaseIcon} inheritViewBox />
                    </IconButton>
                    <Button
                      sx={{ height: "3.5rem", width: "3.5rem" }}
                      data-testid={`cancel-button-${commentary.id}`}
                      onClick={() => {
                        handleCancelCommentEdit();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ height: "3.5rem", width: "3.5rem" }}
                      onClick={saveEdit}
                      disabled={saveEditButtonDisabled}
                    >
                      Save
                    </Button>
                    {isUpdateLoading ? <span>Saving...</span> : null}
                  </>
                )}
              </Stack>
              <Box>
                {commentHovered && commentIsParent ? (
                  <Fade in={commentHovered && commentIsParent} timeout={commentActionFadeDuration}>
                    <Button
                      sx={{ height: "3.5rem", width: "3.5rem" }}
                      onClick={() => {
                        setReplyBoxIsActive(true);
                        setOpen(commentary.id);
                      }}
                      data-testid={`reply-button-${commentary.id}`}
                    >
                      Reply
                    </Button>
                  </Fade>
                ) : null}
                {commentHovered && (
                  <CommentKebabMenu
                    commentary={commentary}
                    setSelectedCommentaryForDelete={setSelectedCommentaryForDelete}
                    setEditingCommentaryId={setEditingCommentaryId}
                  />
                )}
              </Box>
            </Stack>
          </Card>
          {commentIsParent && (
            <>
              <Collapse in={expanded}>
                <Stack sx={{ paddingLeft: "1rem", flexDirection: "column" }}>
                  {commentary.replyComments?.map((commentary) => {
                    return (
                      <Comment
                        commentary={commentary}
                        open={open}
                        setOpen={setOpen}
                        setSelectedCommentaryForDelete={setSelectedCommentaryForDelete}
                        setEditingCommentaryId={setEditingCommentaryId}
                        editingCommentId={editingCommentId}
                      />
                    );
                  })}
                </Stack>
              </Collapse>
              {replyBoxIsActive && open === commentary.id && (
                <>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "1rem",
                      paddingBottom: 2,
                      paddingLeft: "1rem"
                    }}
                  >
                    <UserAvatar
                      avatarWidth={"2.625rem"}
                      avatarHeight={"2.625rem"}
                      fontSize={theme.typography.fontSize.toString()}
                      avatarName={name ?? ""}
                      avatarUserId={userId ?? ""}
                    />
                    <Card
                      variant="outlined"
                      sx={{ width: "60%", height: hideMenuBarReply ? "auto" : "10rem", alignItems: "center" }}
                    >
                      <RichTextEditor
                        ref={replyEditorRef}
                        placeholder="Add a reply..."
                        hideMenuBar={hideMenuBarReply}
                        onKeyDown={handleEscapeKeyPressed}
                        onChange={handleEnableSaveReplyButton}
                        hasMention={true}
                        autoFocus={true}
                        editorWrapperSX={{ height: hideMenuBarReply ? "auto" : "10rem", overflow: "auto" }}
                      />
                    </Card>
                    <IconButton
                      sx={{ height: "3.5rem", width: "3.5rem" }}
                      onClick={() => {
                        setHideMenuBarReply((prev) => !prev);
                        replyEditorRef.current?.focus();
                      }}
                    >
                      <SvgIcon component={MatchCaseIcon} inheritViewBox />
                    </IconButton>
                    <Button
                      sx={{ height: "3.5rem", width: "3.5rem" }}
                      onClick={() => {
                        handleCancelReply();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ height: "3.5rem", width: "3.5rem" }}
                      onClick={saveReply}
                      disabled={saveReplyButtonDisabled}
                    >
                      Save
                    </Button>
                  </Stack>
                  {addCommentaryIsLoading ? <span>Saving...</span> : null}
                </>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
}

interface CommentKebabMenuProps {
  commentary: Commentary;
  setSelectedCommentaryForDelete: Dispatch<SetStateAction<Commentary | null>>;
  setEditingCommentaryId: Dispatch<SetStateAction<CommentProps["editingCommentId"]>>;
}

function CommentKebabMenu({
  commentary,
  setEditingCommentaryId,
  setSelectedCommentaryForDelete
}: CommentKebabMenuProps) {
  const { userId, isCoordinator, isAdmin } = useAuthorization();
  const { approvalRequest } = useARContext();
  const { mutate: decideReturnRequest } = useCommentaryDecideReturnRequest(commentary.approvalRequestId, commentary.id);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const moreActionToggleOpen = Boolean(anchorEl);
  const handleMoreActionToggleOnClicked = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreActionToggleOnClose = () => {
    setAnchorEl(null);
  };

  const COMMENT_MENU_ITEMS: Record<COMMENTARY_MENU_ITEMS, CommentaryMenuItemConfig> = useMemo(
    () => ({
      [COMMENTARY_MENU_ITEMS.Edit]: {
        name: COMMENTARY_MENU_ITEMS.Edit,
        key: "edit",
        onClick: () => {
          setEditingCommentaryId(commentary.id);
          handleMoreActionToggleOnClose();
        }
      },
      [COMMENTARY_MENU_ITEMS.Delete]: {
        name: COMMENTARY_MENU_ITEMS.Delete,
        key: "delete",
        onClick: () => {
          setSelectedCommentaryForDelete(commentary);
          handleMoreActionToggleOnClose();
        }
      },
      [COMMENTARY_MENU_ITEMS.Accept]: {
        name: COMMENTARY_MENU_ITEMS.Accept,
        key: "accept",
        onClick: () => {
          decideReturnRequest(ApprovalRequestReturnStatus.Accepted);
          handleMoreActionToggleOnClose();
        }
      },
      [COMMENTARY_MENU_ITEMS.Decline]: {
        name: COMMENTARY_MENU_ITEMS.Decline,
        key: "decline",
        onClick: () => {
          decideReturnRequest(ApprovalRequestReturnStatus.Declined);
          handleMoreActionToggleOnClose();
        }
      }
    }),
    [setEditingCommentaryId, commentary, setSelectedCommentaryForDelete, decideReturnRequest]
  );

  const memoizedMenu: CommentaryMenuItemConfig[] = useMemo(() => {
    if ((userId === commentary.commentatorId && commentary.type) === CommentaryType.Comment) {
      return [COMMENT_MENU_ITEMS[COMMENTARY_MENU_ITEMS.Edit], COMMENT_MENU_ITEMS[COMMENTARY_MENU_ITEMS.Delete]];
    }
    if (
      ((isCoordinator && userId === approvalRequest?.coordinatorId) || isAdmin) &&
      commentary.type === CommentaryType.ReturnRequest &&
      commentary.approvalRequestReturnStatus === ApprovalRequestReturnStatus.Pending
    ) {
      return [COMMENT_MENU_ITEMS[COMMENTARY_MENU_ITEMS.Accept], COMMENT_MENU_ITEMS[COMMENTARY_MENU_ITEMS.Decline]];
    }
    return [];
  }, [
    COMMENT_MENU_ITEMS,
    approvalRequest?.coordinatorId,
    commentary.approvalRequestReturnStatus,
    commentary.commentatorId,
    commentary.type,
    isCoordinator,
    isAdmin,
    userId
  ]);

  return (
    memoizedMenu.length > 0 && (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={moreActionToggleOpen ? "long-menu" : undefined}
          aria-expanded={moreActionToggleOpen ? "true" : undefined}
          aria-haspopup="true"
          data-testid={`more-action-button-${commentary.id}`}
          onClick={handleMoreActionToggleOnClicked}
          sx={{
            height: "3.5rem",
            width: "3.5rem"
          }}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button"
          }}
          anchorEl={anchorEl}
          open={moreActionToggleOpen}
          onClose={handleMoreActionToggleOnClose}
        >
          {memoizedMenu.map(({ name, key, onClick }) => {
            return (
              <MenuItem key={key} onClick={onClick} data-testid={`${key}-button-${commentary.id}`}>
                {name}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    )
  );
}
