import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { FILE_NAMES } from "@/constants";
import { useARContext, useDisciplineTabContext } from "@/context";
import { ApprovalRequestDisciplineStatus, ApprovalRequestStatus } from "@/interfaces";

interface EmptyTrackingCommentsProps {
  handleSelectTrackingOnClicked?: () => void;
}

export function EmptyTrackingComments({ handleSelectTrackingOnClicked }: EmptyTrackingCommentsProps) {
  const theme = useTheme();
  const { userIsSMEOfDisciplineOrAdmin, approvalRequest } = useARContext();
  const { currentARDiscipline } = useDisciplineTabContext();

  return (
    <Stack>
      <Stack alignItems="center">
        <Box
          component="img"
          data-testid="EmptyTrackingCommentLogoId"
          alt="There is no tracking comment posted at the moment."
          src={`${import.meta.env.REACT_APP_AUTH_REDIRECT_URI}/images/${FILE_NAMES.EMPTY_TRACK_COMMENT_BG}`}
          sx={{ width: "18rem", my: "1rem" }}
        />
      </Stack>
      <Stack alignItems="center">
        <Typography sx={{ fontSize: "24px", lineHeight: "32.02px" }}>No tracking comments</Typography>
        <Stack alignItems="center" sx={{ color: "text.secondary", mt: 1.5, mb: 1.5 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "16px",
              letterSpacing: "0.15px",
              color: theme.palette.text.secondary
            }}
          >
            Select the relevant Tracking Comments for
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "16px",
              letterSpacing: "0.15px",
              color: theme.palette.text.secondary
            }}
          >
            long lead approvals or reviews
          </Typography>
        </Stack>
        <Button
          variant="contained"
          sx={{ width: "12rem", py: 1, px: 3 }}
          onClick={handleSelectTrackingOnClicked}
          disabled={
            !userIsSMEOfDisciplineOrAdmin ||
            approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
            currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised
          }
        >
          Select Tracking
        </Button>
      </Stack>
    </Stack>
  );
}
