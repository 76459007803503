import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ApprovalRequestReturnStatus, Notification, NotificationType, NotificationEvent } from "@/interfaces";
import {
  APPROVAL_REQUEST_QUERY_KEY,
  AR_COMMENTARIES_QUERY_KEY,
  MARK_NOTIFICATION_READ_MUTATION_KEY,
  NOTIFICATIONS_QUERY_KEY,
  SECONDS_IN_MS
} from "@/constants";
import { apiClient } from "@/api/client";
import { updateApprovalRequestReturn } from "@/services";

const BASE_NOTIFICATIONS_URL = "/notifications";

export function useNotifications(
  isRead?: boolean,
  isDismissed?: boolean,
  events?: NotificationEvent[],
  type?: NotificationType[]
) {
  const { data, ...rest } = useQuery({
    queryKey: [NOTIFICATIONS_QUERY_KEY, isRead, isDismissed, events, type],
    queryFn: () =>
      apiClient
        .get<Notification[]>(`${BASE_NOTIFICATIONS_URL}`, {
          params: {
            isRead: isRead ?? null,
            isDismissed: isDismissed ?? null,
            events: events ?? null,
            type: type ?? null
          },
          paramsSerializer: {
            indexes: null
          }
        })
        .then((response) => response.data),
    initialData: [],
    refetchInterval: 60 * SECONDS_IN_MS
  });

  return { notifications: data, ...rest };
}

export function useMarkNotificationsAsRead() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: [MARK_NOTIFICATION_READ_MUTATION_KEY],
    mutationFn: (notificationIds: Notification["id"][]) =>
      apiClient.put<Notification["id"][]>(`${BASE_NOTIFICATIONS_URL}/mark-read`, { notificationIds }),
    onSuccess: () => {
      queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEY]);
    }
  });

  return { markRead: mutate, ...rest };
}

export function useNotificationDecideReturnRequest(approvalRequestId: string, notificationId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (decision: ApprovalRequestReturnStatus) => {
      return updateApprovalRequestReturn({ approvalRequestId, notificationId, decision });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([AR_COMMENTARIES_QUERY_KEY, approvalRequestId]);
      queryClient.invalidateQueries([
        NOTIFICATIONS_QUERY_KEY,
        undefined,
        false,
        undefined,
        [NotificationType.Actionable, NotificationType.InApp]
      ]);
    },
    onError: () => {
      // TODO: Update when implementing application wide error banner
    }
  });
}
