import { createRef, useState, KeyboardEvent } from "react";
import {
  Button,
  Card,
  Stack,
  Tooltip,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { Editor } from "@tiptap/core/dist/packages/core/src/Editor";

import { COLLAB_DELETE_COMMENT_MODAL, COLLAB_DELETE_COMMENT_REPLIES_MODAL } from "@/constants";
import { useARContext, useAuthorization } from "@/context";
import { useAddCommentary, useDeleteCommentary, useGetCommentary } from "@/hooks";
import { Loading } from "@/components";
import { UserAvatar } from "@/components/navigation";
import { RichTextEditor, RichTextEditorRef } from "@/components/rich-text-editor";
import { Comment } from "./Comment";
import { Commentary } from "@/interfaces";

export function CollaborationTab() {
  const theme = useTheme();
  const { approvalRequestId } = useARContext();

  const editorRef = createRef<RichTextEditorRef>();

  const [actionButtonsVisible, setActionButtonsVisible] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [open, setOpen] = useState("");
  const [selectedCommentaryForDelete, setSelectedCommentaryForDelete] = useState<Commentary | null>(null);
  const [editingCommentId, setEditingCommentaryId] = useState<string | null>(null);

  const handleClearContent = () => {
    editorRef.current?.clearContent();
    editorRef.current?.blur();
  };
  const { userId, name } = useAuthorization();

  const { mutate: addCommentary, status } = useAddCommentary(approvalRequestId, undefined, handleClearContent);
  const { data: commentaryData, isSuccess } = useGetCommentary(approvalRequestId);
  const { mutate: deleteCommentary, isLoading: deleteCommentaryIsLoading } = useDeleteCommentary(approvalRequestId);
  const handleEnableSaveButton = (editor: Editor) => {
    setSaveButtonDisabled(editor.getText().trim().length === 0);
  };

  const handleEscapeKeyPressed = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      handleClearContent();
    }
  };

  const renderButton = () => {
    if (!actionButtonsVisible) return null;
    return (
      <>
        <Button
          variant="outlined"
          sx={{ minWidth: "5rem" }}
          disabled={saveButtonDisabled}
          onClick={() => {
            const inputtedComment = editorRef.current?.getContent(true);
            setSaveButtonDisabled(true);
            addCommentary(inputtedComment);
          }}
          data-testid="save-new-comment-button"
        >
          Save
        </Button>
        <Button
          variant="outlined"
          sx={{ minWidth: "5rem" }}
          onClick={() => {
            handleClearContent();
          }}
          data-testid="cancel-new-comment-button"
        >
          Cancel
        </Button>
      </>
    );
  };

  const handleOnBlur = () => {
    if ((editorRef.current?.getContent(false).length ?? 0) === 0) {
      setActionButtonsVisible(false);
    }
  };

  const handleDeleteButtonOnClose = (callback?: () => void) => {
    callback?.();
    setSelectedCommentaryForDelete(null);
  };

  const renderDeleteDialog = () => {
    if (selectedCommentaryForDelete == null) return;

    // the comment is a parent thread and contains reply, use different dialog message.
    const { TITLE, MESSAGE } =
      selectedCommentaryForDelete.replyComments.length > 0
        ? COLLAB_DELETE_COMMENT_REPLIES_MODAL
        : COLLAB_DELETE_COMMENT_MODAL;

    return (
      <Dialog
        open={selectedCommentaryForDelete != null}
        onClose={() => handleDeleteButtonOnClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{MESSAGE}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteButtonOnClose()}>Cancel</Button>
          <Button
            onClick={() =>
              handleDeleteButtonOnClose(() => {
                deleteCommentary(selectedCommentaryForDelete.id);
              })
            }
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Stack direction="row" gap="1.5rem">
        <Stack sx={{ flexDirection: "column" }}>
          <Tooltip title={name ?? ""}>
            <div>
              <UserAvatar
                avatarWidth={"2.625rem"}
                avatarHeight={"2.625rem"}
                fontSize={theme.typography.fontSize.toString()}
                avatarName={name ?? ""}
                avatarUserId={userId ?? ""}
              />
            </div>
          </Tooltip>
        </Stack>
        <Stack sx={{ flexDirection: "column", flexGrow: 1 }}>
          <Card variant="outlined" sx={{ minHeight: "10rem", width: "100%" }}>
            <RichTextEditor
              ref={editorRef}
              placeholder="Add a comment. Use @ to mention a person."
              hideMenuBar={!actionButtonsVisible}
              onFocus={() => {
                setActionButtonsVisible(true);
              }}
              onBlur={() => {
                handleOnBlur();
              }}
              onChange={handleEnableSaveButton}
              onKeyDown={handleEscapeKeyPressed}
              hasMention={true}
              data-testid="input-comment-editor"
            />
          </Card>
        </Stack>
      </Stack>
      <Stack direction="row" gap="1.5rem" alignItems="flex-end" justifyContent="flex-end" mt={2} mb={2}>
        {renderButton()}
      </Stack>
      {status === "loading" ? <span>Saving...</span> : null}
      {!isSuccess ? (
        <Loading />
      ) : (
        <>
          {deleteCommentaryIsLoading ? <span>Deleting...</span> : null}

          {commentaryData.map((commentary) => (
            <Comment
              commentary={commentary}
              open={open}
              setOpen={setOpen}
              setSelectedCommentaryForDelete={setSelectedCommentaryForDelete}
              editingCommentId={editingCommentId}
              setEditingCommentaryId={setEditingCommentaryId}
            />
          ))}
        </>
      )}
      {renderDeleteDialog()}
    </>
  );
}
