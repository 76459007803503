import { IOption } from "@/interfaces";
import { colours } from "@/theme/colour";

export const MUI_LICENSE_KEY =
  "90c2bb4ba47944a56a0a32721a5e3864Tz04MjM0OCxFPTE3Mzg3MTM2MDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";

export const TITLE = {
  AppTitle: "LAMS",
  AdminTitle: "LAMS Administrator"
};

export const APP_BAR_COLOR = {
  ALL: "black",
  ADMIN: colours.adminBackground
};

export const FILE_NAMES = {
  APP_BAR: "RT_2022_Monogram_Red-White_RGB.png",
  HOME_BG: "bg.png",
  EMPTY_TRACK_COMMENT_BG: "undraw_add_tasks.svg",
  COMPANY_LOGO: "RT_Logo.png",
  PERMIT_Map: "Interactive.png"
};

export const IS_TEST_MODE = import.meta.env.MODE === "test";

export const TENANCIES = ["RTA", "RTIO"];

export const MULTI_TENANCY = {
  HEADER: "x-tenancy",
  PARAMETER: "tenancy",
  STORAGE: "tenancy",
  DEFAULT: "RTIO"
};

export const EXTEND_BY_OPTIONS: IOption[] = [
  {
    id: "6",
    value: "6 months"
  },
  {
    id: "12",
    value: "1 year"
  },
  {
    id: "24",
    value: "2 year"
  },
  {
    id: "36",
    value: "3 year"
  },
  {
    id: "48",
    value: "4 year"
  },
  {
    id: "50",
    value: "5 year"
  }
];
