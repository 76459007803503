import { Controller } from "react-hook-form";
import { AppFormDateProps } from "./AppFormProps";
import { FormControl, FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { withARAwareReadOnly } from "@/components/hocs";
import { ARFormSection } from "@/interfaces";
import { HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";

export const AppFormDateInput = ({
  name,
  control,
  label,
  error,
  showError = true,
  "data-testid": dataTestId,
  disabled,
  readOnly,
  onChangeHandler,
  resetError,
  minDate,
  maxDate,
  helperText,
  formHelperErrorClass,
  isHighlight
}: AppFormDateProps) => {
  return (
    <FormControl fullWidth sx={{ border: "1px", borderColor: "red" }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <DatePicker
            onChange={(event) => {
              //manually set time of resultant DateTime
              const currentDateTime = dayjs();
              const modifiedSelectedDate = event
                ?.set("hour", currentDateTime.hour())
                .set("minute", currentDateTime.minute());
              onChange(modifiedSelectedDate);
              onChangeHandler?.(modifiedSelectedDate, name);
              resetError?.();
            }}
            format="DD/MM/YYYY"
            value={value ? dayjs(value) : undefined}
            label={label}
            minDate={minDate}
            maxDate={maxDate}
            ref={ref}
            disabled={disabled}
            readOnly={readOnly}
            slotProps={{
              textField: { inputProps: { "data-testid": dataTestId } }
            }}
            className={isHighlight ? HIGHLIGHT_FIELD_CLASS_NAMES.BACKGROUND : ""}
          />
        )}
      />
      {error?.message && showError ? (
        <FormHelperText
          error
          sx={{ marginBottom: 1, minHeight: 20 }}
          className={error ? formHelperErrorClass : undefined}
        >
          {error.message}
        </FormHelperText>
      ) : (
        <FormHelperText sx={{ minHeight: 20 }}>{helperText ? helperText : null}</FormHelperText>
      )}
    </FormControl>
  );
};

export const ARAwareReadOnlyAppFormDateInput = withARAwareReadOnly(ARFormSection.BasicForm, AppFormDateInput);
