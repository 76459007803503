import { InputHTMLAttributes, useEffect, useMemo, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
  ApprovalRequestDisciplineStatus,
  ApprovalRequestStatus,
  AuthorisationCommentField,
  DisciplineAuthorisationComment,
  DisciplineAuthorisationCommentValue
} from "@/interfaces";
import { useARContext, useDisciplineTabContext } from "@/context";
import { ConfigurableFieldComponent, ConfigurableListContent, ConfigurableTextContent } from "./configurable-fields";
import {
  AuthorisationCommentFieldConfigurations,
  FormComponentConfiguration
} from "@/interfaces/AuthorisationComments";
import { getAuthCommentFieldsFromContent } from "@/utils";
import { useParams } from "react-router-dom";

export function AuthorisationComments() {
  const { disciplineAuthorisationComments, updateDisciplineAuthorisationComments: setDisciplineAuthorisationComments } =
    useDisciplineTabContext();
  const {
    userIsSMEOfDisciplineOrAdmin,
    approvalRequest,
    authoriseDisciplineHandler,
    approvalRequestDisciplines,
    userIsSMEOfAr
  } = useARContext();
  const { approvalRequestDisciplineId } = useParams();

  const [indexedAuthorisationComments, setIndexedAuthorisationComments] = useState<
    Record<DisciplineAuthorisationComment["id"], DisciplineAuthorisationComment>
  >({});

  const [isLabelOnEdit, setIsLabelOnEdit] = useState<Record<DisciplineAuthorisationComment["id"], boolean>>({
    ...Object.keys(disciplineAuthorisationComments).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  });

  useEffect(() => {
    const indexedDisciplineAuthComments: Record<DisciplineAuthorisationComment["id"], DisciplineAuthorisationComment> =
      {};
    const indexedLabelOnEdit: Record<DisciplineAuthorisationComment["id"], boolean> = {};
    disciplineAuthorisationComments?.forEach((disciplineAuthComment) => {
      indexedDisciplineAuthComments[disciplineAuthComment.id] = disciplineAuthComment;
      indexedLabelOnEdit[disciplineAuthComment.id] = false;
    });

    //Todo: Make indexedAuthorisationComments UseMemo instead
    setIndexedAuthorisationComments(indexedDisciplineAuthComments);
    setIsLabelOnEdit(indexedLabelOnEdit);
  }, [disciplineAuthorisationComments, setIndexedAuthorisationComments]);

  const isDisciplineTabAuthorised = useMemo(() => {
    return (
      approvalRequestDisciplines.find((d) => d.id === approvalRequestDisciplineId)?.status ===
      ApprovalRequestDisciplineStatus.Authorised
    );
  }, [approvalRequestDisciplines, approvalRequestDisciplineId]);

  const updateDisciplineAuthCommentValue = (updatedValue: DisciplineAuthorisationCommentValue) => {
    const existingAuthComment = indexedAuthorisationComments[updatedValue.disciplineAuthorisationCommentId];
    const updatedAuthCommentsById = {
      ...indexedAuthorisationComments,
      [updatedValue.disciplineAuthorisationCommentId]: {
        ...existingAuthComment,
        values: existingAuthComment.values.map((item) => {
          if (updatedValue.id === item.id) {
            return {
              ...item,
              value: updatedValue.value,
              childDisciplineAuthorisationCommentValues: updatedValue.childDisciplineAuthorisationCommentValues
            };
          }
          return item;
        })
      }
    };
    setIndexedAuthorisationComments(updatedAuthCommentsById);
    setDisciplineAuthorisationComments(Object.values(updatedAuthCommentsById));
  };

  const deleteDisciplineAuthCommentValue = (deletedValue: DisciplineAuthorisationCommentValue) => {
    const deletedAuthCommentsById = {
      ...indexedAuthorisationComments,
      [deletedValue.disciplineAuthorisationCommentId]: {
        ...indexedAuthorisationComments[deletedValue.disciplineAuthorisationCommentId],
        content: indexedAuthorisationComments[deletedValue.disciplineAuthorisationCommentId].content.filter(
          (fieldName) => fieldName !== `{{${deletedValue.name}}}`
        ),
        values: indexedAuthorisationComments[deletedValue.disciplineAuthorisationCommentId].values.map((item) => {
          if (deletedValue.id === item.id) {
            return {
              ...item,
              action: "delete"
            };
          }
          return item;
        })
      }
    };
    setIndexedAuthorisationComments(deletedAuthCommentsById);
    setDisciplineAuthorisationComments(Object.values(deletedAuthCommentsById));
  };

  const insertDisciplineAuthCommentValue = (fieldName: string, newValue: DisciplineAuthorisationCommentValue) => {
    const fieldContents = indexedAuthorisationComments[newValue.disciplineAuthorisationCommentId].content as string[];
    const indexField = fieldContents.indexOf(`{{${fieldName}}}`);
    fieldContents.splice(indexField + 1, 0, `{{${newValue.name}}}`);

    const newAuthCommentsById = {
      ...indexedAuthorisationComments,
      [newValue.disciplineAuthorisationCommentId]: {
        ...indexedAuthorisationComments[newValue.disciplineAuthorisationCommentId],
        content: fieldContents,
        values: [
          ...indexedAuthorisationComments[newValue.disciplineAuthorisationCommentId].values,
          { ...newValue, action: "insert" }
        ]
      }
    };
    setIndexedAuthorisationComments(newAuthCommentsById);
    setDisciplineAuthorisationComments(Object.values(newAuthCommentsById));
  };

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: "row",
          height: "3rem",
          alignItems: "center"
        }}
      >
        <Typography sx={{ fontSize: "1.25rem" }}>Authorisation Comments</Typography>
      </Stack>
      <Stack
        sx={{ justifyContent: "center", m: "0.5rem", width: "80%" }}
        spacing="1.5rem"
        data-testid="auth-comment-content"
      >
        {disciplineAuthorisationComments.map(({ id, content, isChecked, values }) => {
          return (
            <FormControlLabel
              autoFocus={false}
              key={`label-${id}`}
              sx={{ alignItems: "flex-start" }}
              slotProps={{ typography: { flexGrow: 1 } }}
              control={
                <Checkbox
                  id={`checkbox-${id}`}
                  checked={isChecked}
                  sx={{ mr: "1rem", p: 0 }}
                  onChange={(_, checked) => {
                    if (!isLabelOnEdit[id]) {
                      const updatedAuthCommentsById = {
                        ...indexedAuthorisationComments,
                        [id]: {
                          ...indexedAuthorisationComments[id],
                          isChecked: checked
                        }
                      };
                      setIndexedAuthorisationComments(updatedAuthCommentsById);
                      setDisciplineAuthorisationComments(Object.values(updatedAuthCommentsById));
                    }
                  }}
                  disabled={
                    isLabelOnEdit[id] ||
                    !userIsSMEOfDisciplineOrAdmin ||
                    approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
                    isDisciplineTabAuthorised
                  }
                  inputProps={{ "data-testid": `checkbox-${id}` } as InputHTMLAttributes<HTMLInputElement>}
                />
              }
              label={
                <AuthorisationComment
                  content={content}
                  storedValues={values}
                  updateStoredValues={updateDisciplineAuthCommentValue}
                  deleteStoredValues={deleteDisciplineAuthCommentValue}
                  insertStoredValues={insertDisciplineAuthCommentValue}
                  isEditable={isLabelOnEdit[id]}
                  onEditHandler={(isLabelAction: boolean) => {
                    setIsLabelOnEdit((prev) => ({
                      ...prev,
                      [id]: isLabelAction
                    }));
                  }}
                  isCheck={indexedAuthorisationComments[id]?.isChecked}
                  formAuthCommentFields={indexedAuthorisationComments[id]?.authorisationCommentFormFields}
                  isDisciplineTabAuthorised={isDisciplineTabAuthorised}
                />
              }
            />
          );
        })}
      </Stack>
      <Stack alignItems="center">
        {userIsSMEOfAr ? (
          <Button
            variant="contained"
            sx={{ width: "12rem", py: 1, px: 3 }}
            onClick={() => {
              authoriseDisciplineHandler();
            }}
            disabled={
              approvalRequest.returnRequestCount > 0 ||
              approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
              !userIsSMEOfDisciplineOrAdmin ||
              Object.values(indexedAuthorisationComments).every((commentValue) => !commentValue.isChecked) ||
              isDisciplineTabAuthorised
            }
            data-testid="auth-comments-button"
          >
            Authorise
          </Button>
        ) : null}
      </Stack>
    </Box>
  );
}

interface AuthorisationCommentProps {
  content: (string | string[])[];
  storedValues: DisciplineAuthorisationCommentValue[];
  updateStoredValues: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  deleteStoredValues: (deletedValue: DisciplineAuthorisationCommentValue) => void;
  insertStoredValues: (fieldName: string, newValue: DisciplineAuthorisationCommentValue) => void;
  isEditable: boolean;
  onEditHandler: (isAction: boolean) => void;
  isCheck: boolean;
  formAuthCommentFields?: FormComponentConfiguration[];
  isDisciplineTabAuthorised: boolean;
}

function AuthorisationComment({
  content,
  storedValues,
  updateStoredValues,
  deleteStoredValues,
  insertStoredValues,
  isEditable,
  onEditHandler,
  isCheck,
  formAuthCommentFields,
  isDisciplineTabAuthorised
}: AuthorisationCommentProps) {
  const { userIsSMEOfDisciplineOrAdmin, approvalRequest } = useARContext();

  const fieldValues = useMemo(() => {
    const indexedFieldValues: Record<AuthorisationCommentField["name"], DisciplineAuthorisationCommentValue> = {};
    storedValues?.forEach((valueConfiguration) => {
      indexedFieldValues[valueConfiguration.name] = valueConfiguration;
    });
    return indexedFieldValues;
  }, [storedValues]);

  const onConfigActionHandler = (isEdit: boolean) => {
    onEditHandler(isEdit);
  };

  const indexedFormAuthCommentFields = useMemo(() => {
    const indexedFields: Record<AuthorisationCommentField["name"], FormComponentConfiguration> = {};
    formAuthCommentFields?.forEach((field) => {
      indexedFields[field.name] = field;
    });
    return indexedFields;
  }, [formAuthCommentFields]);

  const generateNewDisciplineAuthCommentValue = (
    disciplineAuthorisationCommentId: string,
    authorisationCommentField: AuthorisationCommentFieldConfigurations,
    parentGuid?: string
  ): DisciplineAuthorisationCommentValue => {
    const newGuid = crypto.randomUUID();
    const nameGuid = parentGuid ?? newGuid;
    return {
      authorisationCommentField: authorisationCommentField,
      id: newGuid,
      name: authorisationCommentField.name.replace("{0}", nameGuid.toUpperCase()),
      value: authorisationCommentField.defaultValue.replaceAll("{0}", nameGuid.toUpperCase()).replaceAll("{1}", ""),
      disciplineAuthorisationCommentId: disciplineAuthorisationCommentId,
      childDisciplineAuthorisationCommentValues: []
    };
  };

  const addChildDisciplineAuthValue = (authorisationCommentField: FormComponentConfiguration) => {
    const newValue = generateNewDisciplineAuthCommentValue(
      storedValues[0].disciplineAuthorisationCommentId,
      authorisationCommentField
    );

    authorisationCommentField.childAuthorisationCommentField.forEach((childField) => {
      newValue.childDisciplineAuthorisationCommentValues?.push(
        generateNewDisciplineAuthCommentValue(storedValues[0].disciplineAuthorisationCommentId, childField, newValue.id)
      );
    });
    insertStoredValues(authorisationCommentField.name, newValue);
  };

  const commentReadonly =
    !isCheck ||
    !userIsSMEOfDisciplineOrAdmin ||
    approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
    isDisciplineTabAuthorised;

  return (
    <Grid container sx={{ pl: 3 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {content?.map((section, index) => {
        if (Array.isArray(section)) {
          return <ConfigurableListContent key={`auth-comment-config-list-${index}`} listItems={section} />;
        }
        const fields = getAuthCommentFieldsFromContent(section);
        if (fields.length > 0) {
          return fields.map((field, fieldIndex) => {
            const diffLayout = ["number", "input", "date"];
            return (
              <Grid sx={{ flexGrow: 1 }} container direction="row" spacing={2}>
                <Grid item xs={12} container direction="row">
                  {fieldValues[field] && (
                    <>
                      <Grid
                        sx={{ pb: 2 }}
                        item
                        xs={
                          fieldValues[field] &&
                          diffLayout.includes(fieldValues[field].authorisationCommentField.componentType)
                            ? 4
                            : 12
                        }
                      >
                        <ConfigurableFieldComponent
                          key={`${field}-${fieldIndex}`}
                          disciplineAuthCommentValue={fieldValues[field]}
                          onUpdate={updateStoredValues}
                          onDelete={deleteStoredValues}
                          isEditable={isEditable}
                          onActionHandler={onConfigActionHandler}
                          disabled={commentReadonly}
                          childValueConfigurations={fieldValues[field].childDisciplineAuthorisationCommentValues}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
                  {indexedFormAuthCommentFields[field] && indexedFormAuthCommentFields[field].name === field && (
                    <Tooltip title={indexedFormAuthCommentFields[field].helpText}>
                      <Button
                        variant="text"
                        disabled={commentReadonly}
                        onClick={(event) => {
                          event.preventDefault();
                          addChildDisciplineAuthValue(indexedFormAuthCommentFields[field]);
                        }}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            );
          });
        }
        return <ConfigurableTextContent data-testid={`auth-comment-config-text-${index}`} content={section} />;
      })}
    </Grid>
  );
}
