import { InternalDependency } from "./InternalDependency";

export enum DisciplineTrackingCommentStatus {
  InProgress = 0,
  Completed = 1
}

export interface DisciplineTrackingCommentNote {
  id: string;
  note: string;
  author: string;
  created: Date;
  authorId: string;
}

export interface DisciplineTrackingComment {
  id?: string;
  trackingCommentId: string;
  comment: string;
  displayOrder: number;
  isChecked: boolean;
  isComplete: boolean;
  isRemoved: boolean;
  isInternal: boolean;
  isLegacy: boolean;
  status: DisciplineTrackingCommentStatus;
  expectedAuthorisationDate?: Date;
  notes: DisciplineTrackingCommentNote[];
  approvalRequestDisciplineId?: string;
  disciplineTrackingCommentDependencies: InternalDependency[];
}

export interface DisciplineTrackingCommentError {
  trackingCommentId: string;
  expectedAuthorisationDate?: string;
  disciplineTrackingCommentDependencies?: string;
}
