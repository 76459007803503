import Typography from "@mui/material/Typography";
import { Grid, Link, Paper, Stack, Switch } from "@mui/material";
import { useAtom } from "jotai";
import { isAdminModeAtom } from "@/stores";
import { UserAvatar } from "./UserAvatar";
import { useAuthorization } from "@/context";
import { UserRole } from "@/interfaces";

function PermissionToggles() {
  const { isAdmin, isSME, isCoordinator, isEndorser, setRole } = useAuthorization();
  const [isAdminMode, setIsAdminMode] = useAtom(isAdminModeAtom);

  const roleToggles: Record<string, UserRole> = {
    Admin: UserRole.Administrator,
    SME: UserRole.TabOwner,
    Coordinator: UserRole.Coordinator,
    Endorser: UserRole.Endorser
  };

  const checkedStates = [isAdmin, isSME, isCoordinator, isEndorser];

  return (
    <Stack spacing={1} px={4} py={2}>
      {isAdmin ? (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ p: 1 }}>Administration Mode</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch
              data-testid="AdministrationModeToggleSwitch"
              checked={isAdminMode}
              onChange={(_, checked) => {
                setIsAdminMode(checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div data-testid="userIsAdminRole">
              {isAdminMode ? <Typography>On</Typography> : <Typography>Off</Typography>}
            </div>
          </Stack>
        </Stack>
      ) : (
        // TODO: render Typography to maintain same height instead of null.
        // this is just to prevent the role toggles push up or push down on the screen
        // when we toggle the Admin switch on or off.
        <Typography sx={{ p: 1 }}>&nbsp;</Typography>
      )}
      {Object.entries(roleToggles).map(([name, role], index) => {
        return (
          <Stack key={`${name}-${index}`} direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ p: 1 }}>{name}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Switch
                data-testid={`${name}ToggleSwitch`}
                checked={checkedStates[index]}
                onChange={(_, checked) => {
                  setRole(role, checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <div data-testid={`is-${name.toLowerCase()}-mode`}>
                {checkedStates[index] ? <Typography>On</Typography> : <Typography>Off</Typography>}
              </div>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}

export function ProfileCard() {
  const { username, name, signOut } = useAuthorization();
  // TODO: Currently token doesn't include the organisation info,
  // in the future, we should include an organisation attribute into token.
  // Interim solution is hardcoded it.
  const organisation = "RTIO";
  const { REACT_APP_VIEW_PROFILE_URL } = import.meta.env;

  return (
    <Paper>
      <Grid container flexDirection={"column"} paddingLeft="1.5em" columnSpacing={3} display={"flex"}>
        <Stack direction="row" p={2} alignItems="center" justifyContent="space-between">
          <Typography fontStyle="bold">{organisation}</Typography>
          <Link data-testid="SignOutButtonId" onClick={signOut} style={{ cursor: "pointer" }}>
            <Typography> Sign Out</Typography>
          </Link>
        </Stack>
        <Grid item flexDirection={"row"} padding="2em" width="25em" paddingLeft="5em" display={"flex"}>
          <Grid item alignItems={"center"}>
            <UserAvatar avatarWidth={"2.5em"} avatarHeight={"2.5em"} fontSize={"2.5rem"} />
          </Grid>

          <Grid item paddingLeft={"1em"}>
            <Grid sx={{ flexDirection: "column" }}>
              <Typography fontSize={25}>
                <b>{`${name}`}</b>
              </Typography>
              <Typography fontSize={10}>{username}</Typography>
              <a
                data-testid="ViewAccountButtonId"
                style={{ paddingTop: "2em" }}
                target="_blank"
                rel="noreferrer"
                href={REACT_APP_VIEW_PROFILE_URL}
              >
                <Typography fontSize={10}> View account</Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <PermissionToggles />
      </Grid>
    </Paper>
  );
}
