import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import {
  AR_DISCIPLINE_TRACKING_COMMENTS_INTERNAL_DEPENDENCIES_QUERY_KEY,
  AR_DISCIPLINE_TRACKING_COMMENTS_QUERY_KEY,
  AR_UNEXPECTED_ERROR_BANNER
} from "@/constants";
import { DisciplineTrackingComment, InternalDependency } from "@/interfaces";
import { getDisciplineTrackingComments, updateDisciplineTrackingComment, getInternalDependencies } from "@/services";
import { useARContext } from "@/context";
import { useEffect } from "react";

export function useDisciplineTrackingComments(approvalRequestDisciplineId?: string) {
  const { appendAlertBanner } = useARContext();

  const { error, ...rest } = useQuery({
    queryKey: [AR_DISCIPLINE_TRACKING_COMMENTS_QUERY_KEY, approvalRequestDisciplineId],
    queryFn: () => getDisciplineTrackingComments(approvalRequestDisciplineId!),
    enabled: approvalRequestDisciplineId !== undefined,
    initialData: []
  });

  useEffect(() => {
    if (error) {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  }, [error, appendAlertBanner]);

  return { ...rest, error };
}

export function useUpdateDisciplineTrackingComment(
  onSuccessHandler: (updatedDisciplineTrackingComment: DisciplineTrackingComment) => void
) {
  const { appendAlertBanner } = useARContext();

  return useMutation({
    mutationFn: updateDisciplineTrackingComment,
    onSuccess: (data) => {
      onSuccessHandler(data);
    },
    onError: () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  });
}

export function useInternalDependencies(approvalRequestId: string): UseQueryResult<InternalDependency[]> {
  return useQuery({
    queryKey: [AR_DISCIPLINE_TRACKING_COMMENTS_INTERNAL_DEPENDENCIES_QUERY_KEY, approvalRequestId],
    queryFn: () => getInternalDependencies(approvalRequestId),
    initialData: []
  });
}
