import { ApprovalRequestStatus } from "@/interfaces";

export const AR_FORM = {
  SAVE_AR_SUCCESSFUL: "Approvals Request has been saved",
  SUBMIT_AR_SUCCESSFUL: "Approvals Request has been submitted",
  RETURN_AR_SUCCESSFUL: "Approvals Request has been returned",
  CREATE_MODE_HEADER: "Create AR",
  EDIT_MODE_HEADER: "Edit AR",
  VIEW_MODE_HEADER: "View AR",
  DISTRIBUTE_AR_SUCCESSFUL: "Approvals Request has been distributed",
  PERMIT_AUTHORISED_SUCCESSFUL: "Permit has been authorised",
  MODIFY_AR_SUCCESSFUL: "Approvals Request has been changed to draft"
};

// @ts-expect-error: Not all statuses require a string mapping.
export const AR_STATUSES: Record<ApprovalRequestStatus, string> = {
  [ApprovalRequestStatus.New]: "New",
  [ApprovalRequestStatus.Draft]: "Draft",
  [ApprovalRequestStatus.Submitted]: "Submitted",
  [ApprovalRequestStatus.Distributed]: "Distributed",
  [ApprovalRequestStatus.DraftPermit]: "Draft Permit",
  [ApprovalRequestStatus.PermitIssued]: "Permit Issued",
  [ApprovalRequestStatus.Authorised]: "Authorised"
};

export const BUTTON_LABELS = {
  AMEND: "Amend",
  APPLY: "Apply",
  CANCEL: "Cancel",
  DELETE: "Delete",
  DISCARD: "Discard",
  MODIFY: "Modify",
  SUBMIT: "Submit",
  CREATE: "Create",
  ADD: "Add",
  AUTHORISE: "Authorise",
  RETURN: "Return",
  SAVE: "Save"
};

export const HIGHLIGHT_FIELD_CLASS_NAMES = {
  BACKGROUND: "ar-details-change-background-highlight",
  BORDER: "ar-details-change-border-highlight"
};

export const CHANGE_DETAILS_CLASS_NAME = "ar-details-change-border-selected";
