import { atom } from "jotai";

import { getFilteredApprovalRequestTypes } from "@/services";
import { ApprovalRequestType } from "@/interfaces";

export const approvalRequestTypesRefData = atom(() => {
  return getFilteredApprovalRequestTypes({ isEnabled: true }).then(({ approvalRequestTypeList }) => {
    return approvalRequestTypeList.reduce((acc: Record<string, ApprovalRequestType>, curr) => {
      acc[curr.id] = { ...curr };
      return acc;
    }, {});
  });
});
