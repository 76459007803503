import { apiClient } from "@/api/client";
import { GET_APPROVAL_REQUEST_TYPES_MUTATION_KEY } from "@/constants";
import { ApprovalRequestTypeResponse, ApprovalRequestTypesParams } from "@/interfaces";
import { useQuery } from "@tanstack/react-query";

const approvalRequestTypes = "/approvalRequestType";

export function useGetApprovalRequestTypes(params: ApprovalRequestTypesParams) {
  return useQuery({
    queryKey: [GET_APPROVAL_REQUEST_TYPES_MUTATION_KEY],
    queryFn: () =>
      apiClient
        .get<ApprovalRequestTypeResponse>(approvalRequestTypes, {
          params: { ...params }
        })
        .then((response) => response.data)
  });
}
