import { atom, createStore } from "jotai";
import { BlockNavigationModalProps } from "@/components/BlockNavigationModal";
import { atomWithReset } from "jotai/utils";
import { SnackbarConfig } from "@/interfaces";
import { DEFAULT_SNACKBAR_CONFIG } from "@/constants";

export const appDataStore = createStore();

export const isAdminModeAtom = atom(false);
export const navigationBlockerAtom = atomWithReset<BlockNavigationModalProps>({} as BlockNavigationModalProps);

export const snackBarAtom = atomWithReset<SnackbarConfig>(DEFAULT_SNACKBAR_CONFIG as SnackbarConfig);
