import { Suspense, useEffect, useMemo, useState } from "react";
import { Outlet, generatePath } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { AttachFile, Forum, ListAlt, Schedule, VerifiedOutlined, Map } from "@mui/icons-material";
import {
  ApprovalRequestDisciplineStatus,
  ApprovalRequestStatus,
  NavigationLink,
  Notification,
  NotificationType
} from "@/interfaces";
import {
  APPROVAL_REQUEST_PATH,
  AR_UNDER_AMENDMENT_SME,
  AR_UNDER_AMENDMENT_COORDINATOR,
  AR_RETURN_REQUEST_SME,
  APPROVAL_REQUEST_TABS
} from "@/constants";
import { getEnumValueByNumber } from "@/utils";
import { useARContext, useAuthorization, usePushNotificationsContext } from "@/context";
import { DisciplineStatusIcon, Loading, ApprovalRequestAlertBanner } from "@/components";
import { NavigationDrawer } from "@/components/navigation";
import { ARHeader } from "@/features/myRequests/components/ARHeader";
import { getAttachmentsCount } from "@/services";
import { useMarkNotificationsAsRead, useNotifications } from "@/hooks";

export function ApprovalRequestLayout() {
  const { markRead } = useMarkNotificationsAsRead();
  const { isBeingAmended, isBeingReturned } = usePushNotificationsContext();
  const { notifications } = useNotifications(false, false, undefined, [
    NotificationType.Unread,
    NotificationType.InApp
  ]);

  const {
    approvalRequest: { approvalRequestStatus, returnedCount },
    approvalRequestId,
    approvalRequestDisciplines,
    alertBanners: alertBanner,
    userIsSMEOfDiscipline,
    attachmentCountIndicator,
    setAttachmentCountIndicator,
    appendAlertBanner
  } = useARContext();
  const { isCoordinator } = useAuthorization();

  const [tabLinks, setTabLinks] = useState<NavigationLink[]>([]);

  const defaultTabs: NavigationLink[] = useMemo(() => {
    const attachmentCountIndicatorText = attachmentCountIndicator > 0 ? ` (${attachmentCountIndicator})` : "";
    return [
      {
        path: generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId }),
        label: APPROVAL_REQUEST_TABS.DETAILS,
        icon: <ListAlt />,
        displayOrder: 1
      },
      {
        path: generatePath(APPROVAL_REQUEST_PATH.MAP_TAB, { approvalRequestId }),
        label: APPROVAL_REQUEST_TABS.MAP,
        icon: <Map />,
        displayOrder: 2
      },
      {
        path: generatePath(APPROVAL_REQUEST_PATH.ATTACHMENTS, { approvalRequestId }),
        label: APPROVAL_REQUEST_TABS.ATTACHMENTS + attachmentCountIndicatorText,
        icon: <AttachFile />,
        displayOrder: 3
      },
      {
        path: generatePath(APPROVAL_REQUEST_PATH.COLLABORATION, { approvalRequestId }),
        label: APPROVAL_REQUEST_TABS.COLLABORATION,
        icon: <Forum />,
        displayOrder: 4
      },
      {
        path: generatePath(APPROVAL_REQUEST_PATH.HISTORY, { approvalRequestId }),
        label: APPROVAL_REQUEST_TABS.HISTORY,
        icon: <Schedule />,
        displayOrder: 18
      }
    ];
  }, [approvalRequestId, attachmentCountIndicator]);

  const dynamicTabs: NavigationLink[] = useMemo(() => {
    const permitTab: NavigationLink[] =
      returnedCount > 0 || approvalRequestStatus >= ApprovalRequestStatus.Distributed
        ? [
            {
              path: generatePath(APPROVAL_REQUEST_PATH.PERMIT_TAB, { approvalRequestId }),
              label: APPROVAL_REQUEST_TABS.PERMIT,
              icon: <VerifiedOutlined />,
              displayOrder: 4
            }
          ]
        : [];

    const disciplineTab = approvalRequestDisciplines.map((discipline) => ({
      path: generatePath(APPROVAL_REQUEST_PATH.DISCIPLINE_TAB, {
        approvalRequestId: approvalRequestId,
        approvalRequestDisciplineId: discipline.id
      }),
      label: discipline.name,
      displayOrder: defaultTabs.length + discipline.displayOrder,
      icon: <DisciplineStatusIcon status={getEnumValueByNumber(ApprovalRequestDisciplineStatus, discipline.status)} />
    }));
    return [...permitTab, ...disciplineTab];
  }, [approvalRequestDisciplines, approvalRequestId, approvalRequestStatus, defaultTabs.length, returnedCount]);

  useEffect(() => {
    setTabLinks([...defaultTabs, ...dynamicTabs]);
  }, [defaultTabs, dynamicTabs]);

  useEffect(() => {
    const notificationIds = notifications.reduce((acc: Notification["id"][], notification) => {
      if (notification.approvalRequestId === approvalRequestId) {
        acc.push(notification.id);
      }
      return acc;
    }, []);

    if (notificationIds.length > 0) {
      markRead(notificationIds);
    }

    getAttachmentsCount(approvalRequestId).then((attachmentsCount) => {
      setAttachmentCountIndicator(attachmentsCount);
    });
  }, [approvalRequestId, markRead, notifications, setAttachmentCountIndicator]);

  useEffect(() => {
    if (userIsSMEOfDiscipline || isCoordinator) {
      if (isBeingAmended(approvalRequestId)) {
        const message = userIsSMEOfDiscipline ? AR_UNDER_AMENDMENT_SME : AR_UNDER_AMENDMENT_COORDINATOR;
        appendAlertBanner({
          ...message,
          severity: "warning"
        });
      } else if (isBeingReturned(approvalRequestId) && userIsSMEOfDiscipline) {
        appendAlertBanner({
          ...AR_RETURN_REQUEST_SME,
          severity: "warning"
        });
      }
    }
  }, [approvalRequestId, isBeingAmended, appendAlertBanner, userIsSMEOfDiscipline, isCoordinator, isBeingReturned]);

  return (
    <Box id="ar-layout-wrapper" className="layout-wrapper">
      <ARHeader />
      <NavigationDrawer showToggle={false} navigationLinks={tabLinks} paddingTop={16} />
      <Box id="ar-layout-content" ml="18rem" mt="4rem" sx={{ height: "calc(100% - 4rem)" }}>
        <Suspense fallback={<Loading prefix="Approval Request" />}>
          {alertBanner.map((banner) => (
            <ApprovalRequestAlertBanner key={banner.id} {...banner} />
          ))}
          <Stack p="1.5rem" sx={{ height: "100%" }}>
            <Outlet />
          </Stack>
        </Suspense>
      </Box>
    </Box>
  );
}
