import { Suspense, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import { AssignmentInd, MonitorHeart, Search } from "@mui/icons-material";
import { Stack } from "@mui/material";

import { Reporting } from "@/assets/icons";
import { NavigationLink, NotificationType } from "@/interfaces";
import { PATH } from "@/constants";
import { useNotifications } from "@/hooks";
import { Loading } from "@/components";
import { NavigationDrawer } from "@/components/navigation";

export function NavigationLayout() {
  const { notifications } = useNotifications(false, false, undefined, [NotificationType.Unread]);

  const navigationLinks: NavigationLink[] = useMemo(
    () => [
      {
        path: PATH.SEARCH,
        label: "Search",
        icon: <Search />,
        displayOrder: 1
      },
      {
        path: PATH.MY_REQUESTS,
        label: "My Requests",
        icon: <AssignmentInd />,
        displayOrder: 2,
        badge: notifications.length > 0
      },
      {
        path: PATH.DETECTED_INTERCEPTS,
        label: "Detected Intercepts",
        icon: <MonitorHeart />,
        displayOrder: 3
      },
      {
        path: PATH.REPORTING,
        label: "Reporting",
        icon: <Reporting />,
        displayOrder: 4,
        disabled: true
      }
    ],
    [notifications]
  );

  return (
    <Stack direction="row" className="layout-wrapper">
      <NavigationDrawer showToggle={true} navigationLinks={navigationLinks} />
      <Box className="content-container">
        <Suspense fallback={<Loading prefix="Main Layout" />}>
          <Outlet />
        </Suspense>
      </Box>
    </Stack>
  );
}
