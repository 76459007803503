import { Divider, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { colours } from "@/theme/colour";

import { UserAvatar } from "../navigation/UserAvatar";
import { UnreadBadge } from "../UnreadBadge";
import { Notification } from "@/interfaces";
import dayjs from "dayjs";
import { NotificationActionButtons } from "./NotificationActionButtons";

interface NotificationCardProps extends Notification {}

export const NotificationCard = ({
  id,
  approvalRequestId,
  event,
  referenceNo,
  senderId,
  senderName,
  timestamp,
  title,
  arTitle,
  content,
  isRead,
  isDismissed
}: NotificationCardProps) => {
  return (
    <UnreadBadge visible={!isRead}>
      <Stack rowGap={1} width="400px">
        <Stack direction="row" columnGap={2} alignItems="center">
          <UserAvatar
            avatarWidth={"50px"}
            avatarHeight={"50px"}
            fontSize={"1.25rem"}
            avatarUserId={senderId}
            avatarName={senderName}
          />
          <Stack>
            <Typography noWrap width={230} sx={{ textOverflow: "ellipsis" }}>
              {senderName}
            </Typography>
            <Typography>{dayjs(timestamp).format("DD MMM")}</Typography>
          </Stack>
          <CheckCircleOutlineIcon fontSize="small" sx={{ color: colours.homePage.iconDisabled, ml: "auto" }} />
        </Stack>
        <Typography fontWeight="700" noWrap overflow="hidden" textOverflow="ellipsis">
          {title}
        </Typography>
        <Typography fontWeight="700" noWrap overflow="hidden" textOverflow="ellipsis">
          {referenceNo} {arTitle}
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical",
            whiteSpace: "normal"
          }}
        >
          {content}
        </Typography>
        {!isDismissed ? (
          <Stack direction="row" justifyContent="space-around" spacing={2} mt={1}>
            <NotificationActionButtons
              notificationId={id}
              approvalRequestId={approvalRequestId}
              notificationEvent={event}
            />
          </Stack>
        ) : null}
        <Divider sx={{ mt: 1 }} />
      </Stack>
    </UnreadBadge>
  );
};
