import { createTheme } from "@mui/material";
import { colours } from "./colour";
import { CSSProperties } from "react";

export const userTheme = createTheme({
  palette: {
    primary: {
      main: colours.background
    },
    secondary: {
      main: colours.background
    },
    info: {
      main: colours.iconBackground
    },
    text: {
      primary: colours.primaryText,
      secondary: colours.secondaryText
    },
    error: {
      main: colours.error
    }
  },
  typography: {
    poster: {
      color: colours.primaryText
    },
    button: {
      textTransform: "none"
    },
    body2: {
      fontSize: "14px"
    }
  }
});

export const adminTheme = createTheme({
  palette: {
    primary: {
      main: colours.background
    },
    secondary: {
      main: colours.background
    },
    info: {
      main: colours.iconBackground
    },
    text: {
      primary: colours.primaryText,
      secondary: colours.secondaryText
    },
    error: {
      main: colours.error
    }
  },
  typography: {
    poster: {
      color: colours.primaryText
    }
  }
});

// Update the necessary typings for MUI
declare module "@mui/material/styles" {
  interface TypographyVariants {
    poster: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    poster: true;
    h3: false;
  }
}
