import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid } from "@mui/material";
import { AppFormCheckboxProps } from "./AppFormProps";
import { InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";

export const AppFormCheckbox = ({
  name,
  control,
  options,
  error,
  size,
  "data-testid": dataTestId
}: AppFormCheckboxProps) => {
  return (
    <FormControl fullWidth size={size}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <FormGroup>
            <Grid container sx={{ ml: 1.2 }}>
              {options.map((option) => {
                return (
                  <Grid item xs={6}>
                    <FormControlLabel
                      key={option.id}
                      data-testid={dataTestId}
                      value={value}
                      onChange={() => {
                        const updatedValues = value.includes(option.id)
                          ? value.filter((id: string) => id !== option.id)
                          : [...value, option.id];
                        onChange(updatedValues);
                      }}
                      control={
                        <Checkbox
                          id={`checkbox-${option.id}`}
                          checked={value.includes(option.id)}
                          value={option.id}
                          sx={{ pb: 1 }}
                          inputProps={
                            { "data-testid": `checkbox-${option.id}` } as InputHTMLAttributes<HTMLInputElement>
                          }
                        />
                      }
                      label={option.value}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormGroup>
        )}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};
