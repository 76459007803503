export enum NotificationEvent {
  InterceptDetected = 1,
  InterceptRequiresAction = 2,
  ARSubmitted = 3,
  ARReturnRequested = 4,
  ARReturned = 5,
  ARDistributed = 6,
  ARAmended = 7,
  ARPermitDrafted = 8
}

export enum NotificationType {
  InApp = 1,
  Unread = 2,
  Actionable = 3,
  RealTime = 4
}

export interface Notification {
  id: string;
  approvalRequestId: string;
  event: NotificationEvent;
  type: NotificationType;
  senderName: string;
  senderId: string;
  referenceNo: string;
  title: string;
  arTitle: string;
  content: string;
  isRead: boolean;
  isDismissed: boolean;
  timestamp: Date;
}
