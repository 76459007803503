export const colours = {
  /**
   * A helper for making something see-thru.
   */
  transparent: "#00000000",

  /**
   * The default text color in many components.
   */
  //text: palette.neutral100,

  /**
   * Secondary text information.
   */
  //textDim: palette.neutral600,

  /**
   * The default color of the screen background.
   */
  background: "#065DAF",

  /**
   * The color of the screen alternative background.
   */
  adminBackground: "#D33030",

  /**
   * The default border color.
   */
  //border: palette.neutral400,

  /**
   * The default text color.
   */
  primaryText: "#000000",

  /**
   * The default text color.
   */
  secondaryText: "#000000",

  /**
   * The default info background color.
   */
  infoBackground: "#d3d3d366",

  /**
   * The default text color.
   */
  iconBackground: "#FFFFFF",

  /**
   * The default svg icon.
   */
  icon: "rgba(0, 0, 0, 0.6)",

  /**
   * The main tinting color.
   */
  // tint: palette.primary500,

  /**
   * A subtle color used for lines.
   */
  //separator: palette.neutral300,

  /**
   * Error messages.
   */
  error: "#FF0000",

  /**
   * Error Background.
   *
   */
  // errorBackground: palette.angry100,

  sidePanel: "#EFEFEF",

  hyperlink: "#1976D2",

  selected: "#1976d214",

  replyHover: "#F5F5F5",

  /* Notifications */
  unreadNotification: "#FF0000",

  /* Landing Page */
  homePage: {
    icon: "#212121",
    iconHover: "#212121DD",
    iconDisabled: "#414141AA"
  },

  /* Dialogs */
  dialog: {
    subtextColour: "#808080"
  }
};
