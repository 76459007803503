import { CancelToken } from "axios";
import { apiClient } from "@/api/client";
import {
  ApprovalRequestTypeResponse,
  ApprovalRequestTypesParams,
  AttachmentItem,
  CreateNewARRequest,
  CreateNewARResponse,
  ApprovalRequestBase,
  ReturnApprovalRequest,
  UpdateApprovalRequestReturnRequest
} from "@/interfaces";

const approvalRequestTypes = "/approvalRequestType";
const approvalRequest = "/approvalRequest";
const approvalRequestReturn = "/approvalRequestReturn";

export const getAllApprovalRequestTypes = () =>
  apiClient.get<ApprovalRequestTypeResponse>(`${approvalRequestTypes}/all`).then((response) => response.data);

export const getFilteredApprovalRequestTypes = (params: ApprovalRequestTypesParams) =>
  apiClient
    .get<ApprovalRequestTypeResponse>(approvalRequestTypes, {
      params: { ...params }
    })
    .then((response) => response.data);

export const createNewApprovalRequest = (request: CreateNewARRequest) =>
  apiClient.post<CreateNewARResponse>(approvalRequest, request).then((response) => response.data);

export const saveAttachment = (approvalRequestId: string, fileData: FormData, cancelToken?: CancelToken) =>
  apiClient.post<AttachmentItem>(`${approvalRequest}/${approvalRequestId}/attachment`, fileData, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    cancelToken: cancelToken
  });

export const deleteAttachment = (approvalRequestId: string, attachmentId: string) =>
  apiClient.delete(`${approvalRequest}/${approvalRequestId}/attachment/${attachmentId}`);

export const downloadAttachment = (approvalRequestId: string, attachmentId: string) =>
  apiClient
    .get(`${approvalRequest}/${approvalRequestId}/attachment/${attachmentId}`, {
      responseType: "blob"
    })
    .then(async (resp) => {
      // Convert data into URL object for download.
      const objectURL = URL.createObjectURL(resp.data);

      // Generate anchor tag and trigger download.
      const fileDownload = document.createElement("a");
      fileDownload.href = objectURL;
      fileDownload.setAttribute("download", resp.headers["x-filename"]);
      document.body.appendChild(fileDownload);
      fileDownload.click();

      // Clean up elements and data.
      document.body.removeChild(fileDownload);
      URL.revokeObjectURL(objectURL);
    });

export const getAttachments = (approvalRequestId: string) =>
  apiClient
    .get<AttachmentItem[]>(`${approvalRequest}/${approvalRequestId}/attachment`)
    .then((response) => response.data);

export const getAttachmentsCount = (approvalRequestId: string) =>
  apiClient.get<number>(`${approvalRequest}/${approvalRequestId}/attachment/count`).then((response) => response.data);

export const returnApprovalRequest = (returnApprovalRequestDetails: ReturnApprovalRequest) =>
  apiClient
    .post<ApprovalRequestBase>(`${approvalRequestReturn}`, returnApprovalRequestDetails)
    .then((response) => response.data);

export const updateApprovalRequestReturn = (updateApprovalRequestReturnRequest: UpdateApprovalRequestReturnRequest) =>
  apiClient
    .put<ApprovalRequestBase>(`${approvalRequestReturn}`, updateApprovalRequestReturnRequest)
    .then((response) => response.data);
