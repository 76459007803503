import { Navigate, RouteObject } from "react-router-dom";

import { APPROVAL_REQUEST_PATH } from "@/constants";
import { ApprovalRequestLayout } from "@/layouts";
import { ApprovalRequestContextProvider, DisciplineTabContextProvider } from "@/context";
import { DisciplineTab } from "@/components";
import { CollaborationTab } from "@/components/my-requests/collaboration";
import { ARDetailsForm } from "@/features/myRequests/components/ARDetailsForm";
import { AttachmentTab } from "@/components/my-requests/attachment/AttachmentTab";
import { PermitTab } from "@/components/my-requests/permit";
import { MapTab } from "@/components/my-requests/map/MapTab";
import { HomePage } from "@/pages";

export const ApprovalRequestTabRoutes: RouteObject = {
  element: (
    <ApprovalRequestContextProvider>
      <ApprovalRequestLayout />
    </ApprovalRequestContextProvider>
  ),
  errorElement: <HomePage />,
  children: [
    {
      path: APPROVAL_REQUEST_PATH.BASE,
      element: <Navigate replace to="details" />
    },
    {
      path: APPROVAL_REQUEST_PATH.DETAILS,
      element: <ARDetailsForm />
    },
    {
      path: APPROVAL_REQUEST_PATH.MAP_TAB,
      element: <MapTab />
    },
    {
      path: APPROVAL_REQUEST_PATH.ATTACHMENTS,
      element: <AttachmentTab />
    },
    {
      path: APPROVAL_REQUEST_PATH.COLLABORATION,
      element: <CollaborationTab />
    },
    {
      path: APPROVAL_REQUEST_PATH.PERMIT_TAB,
      element: <PermitTab />
    },
    {
      path: APPROVAL_REQUEST_PATH.HISTORY,
      element: <>This is Discipline HISTORY Components</> //TODO: Add in Tab Component
    },
    // This is intentionally last since the path is dynamic.
    {
      path: APPROVAL_REQUEST_PATH.DISCIPLINE_TAB,
      element: (
        <DisciplineTabContextProvider>
          <DisciplineTab />
        </DisciplineTabContextProvider>
      )
    }
  ]
};
