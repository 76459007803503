import dayjs from "dayjs";
import { array, boolean, date, object } from "yup";

import { AR_TC_ERRORS, MANDATORY_FIELD } from "@/constants";

export const trackingCommentsSchema = array().of(
  object({
    isInternal: boolean(),
    isLegacy: boolean(),
    expectedAuthorisationDate: date().test(
      "Mandatory if not internal",
      (value, { parent: { isInternal, isChecked, trackingCommentId, isLegacy }, createError }) => {
        const dayjsDate = value ? dayjs(value) : undefined;
        const invalidDate = dayjsDate === undefined || !dayjsDate.isValid();

        if (!isChecked || isLegacy) {
          return true;
        }

        if (dayjsDate && !dayjsDate.isValid()) {
          return createError({
            path: "expectedAuthorisationDate",
            message: AR_TC_ERRORS.VALID_DATE,
            params: {
              trackingCommentId
            }
          });
        }

        if (!isLegacy && !isInternal && invalidDate) {
          return createError({
            path: "expectedAuthorisationDate",
            message: MANDATORY_FIELD.MANDATORY_DATE,
            params: {
              trackingCommentId
            }
          });
        }

        return true;
      }
    ),
    disciplineTrackingCommentDependencies: array().test(
      "At least one dependency must be selected",
      (value, { parent: { isInternal, isChecked, trackingCommentId }, createError }) => {
        if (!isChecked) {
          return true;
        }
        if (!isInternal) {
          return true;
        }

        if (value?.length === 0) {
          return createError({
            path: "disciplineTrackingCommentDependencies",
            message: MANDATORY_FIELD.DISCIPLINE_DEPENDENCIES,
            params: {
              trackingCommentId
            }
          });
        }

        return true;
      }
    )
  })
);
