import React from "react";
import { Box, Typography } from "@mui/material";
import { useSetAtom } from "jotai";

import { ACCEPTED_FILE_EXTENSIONS, ATTACHMENT_ERRORS } from "@/constants";
import { FileValidationResult, AddApprovalRequestMap } from "@/interfaces";
import { uploadInProgressAtom } from "@/stores";
import { useARContext } from "@/context";
import { AttachmentUploadBox } from "../../../features/myRequests/components/AttachmentUploadBox";
import { useAddApprovalRequestMap } from "@/hooks/api/ApprovalRequestHooks";

interface MapUploadProps {
  setIsMapExist: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export const MapUpload = ({ setIsMapExist }: MapUploadProps) => {
  const { approvalRequestId } = useARContext();
  const setUploadInProgress = useSetAtom(uploadInProgressAtom);

  setUploadInProgress(false);

  const fileTypeIsValid = (fileName: string) => {
    const fileExtension = `.${fileName.split(".").pop()}`;
    return ACCEPTED_FILE_EXTENSIONS.includes(fileExtension.toLocaleLowerCase());
  };

  const isValidFile = (file: File) => {
    const validationResult: FileValidationResult = {
      isValid: true,
      errorMessage: null
    };

    if (!fileTypeIsValid(file.name)) {
      validationResult.isValid = false;
      validationResult.errorMessage = ATTACHMENT_ERRORS.INVALID_FILE_TYPE;
    }
    return validationResult;
  };

  const { mutate: updateMapMutation } = useAddApprovalRequestMap(approvalRequestId, () => {
    setIsMapExist(true);
    setUploadInProgress(false);
  });

  const uploadFile = async (fileToUpload: File) => {
    const fileValidationResult = isValidFile(fileToUpload);

    if (!fileValidationResult.isValid) {
      return;
    }

    setUploadInProgress(true);

    const fileData = new FormData();
    fileData.append("file", fileToUpload);

    const addMapCommand: AddApprovalRequestMap = {
      approvalRequestId,
      fileData
    };

    updateMapMutation(addMapCommand);
  };

  const customFooter = (
    <Typography data-testid="upload-footer-display" variant="body2" mt="0.5rem" color="#0000008A">
      Shape Zip File (max. 5M)
    </Typography>
  );

  return (
    <Box data-testid="map-upload-display" flexDirection="column" flexGrow="1" height="100%" m="0 auto">
      <AttachmentUploadBox handleUpload={uploadFile} fillHeight={true} customFooter={customFooter} />
    </Box>
  );
};
