import { toKebabCase } from "@/utils";
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow
} from "@mui/material";
import { PropsWithChildren } from "react";

interface DialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  actions: DialogAction[];
  content?: string;
}

export interface DialogAction {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  isFormSubmit?: boolean;
}

export function Dialog({ open, onClose, title, content, actions, children }: PropsWithChildren<DialogProps>) {
  return (
    <MuiDialog open={open} TransitionComponent={Grow} onClose={onClose} data-testid={toKebabCase(title) + "-dialog"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ p: 1, px: 3 }}>
        {children ? children : <DialogContentText>{content}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) => (
          <Button
            key={index}
            disabled={action.disabled}
            onClick={action.onClick}
            type={action.isFormSubmit ? "submit" : "button"}
            color="primary"
            data-testid={toKebabCase(action.label) + "-modal-button"}
          >
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </MuiDialog>
  );
}
