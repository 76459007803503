import { ADMIN_SETTINGS_PATH, TITLES } from "@/constants";
import { useHubList } from "@/hooks";
import { toKebabCase } from "@/utils";
import { List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

interface HubLink {
  path: string;
  label: string;
  displayOrder: number;
}

export function HubConfiguration() {
  const navigate = useNavigate();
  const { data: hubs } = useHubList();

  const hubTabs: HubLink[] = useMemo(() => {
    return hubs.map((hub, index) => ({
      path: generatePath(ADMIN_SETTINGS_PATH.USER_HUB_MATRIX, {
        hubId: hub.id!
      }),
      label: hub.value!,
      displayOrder: index + 1
    }));
  }, [hubs]);

  return (
    <Paper>
      <Typography variant="h5" sx={{ p: "1rem" }}>
        {TITLES.hubs}
      </Typography>
      <Stack
        sx={{
          p: "1rem",
          maxHeight: "80vh",
          overflow: "auto"
        }}
      >
        <List>
          {hubTabs
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(({ path, label }) => (
              <ListItem key={label} disablePadding>
                <ListItemButton
                  data-testid={`${toKebabCase(label)}-button`}
                  selected={path === location.pathname}
                  onClick={() => navigate(path)}
                >
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Stack>
    </Paper>
  );
}
