import { useState, SyntheticEvent } from "react";
import { Box, SxProps, Tab, Typography } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";

import { NotificationEvent, NotificationType } from "@/interfaces";
import { useAuthorization } from "@/context";
import { useNotifications } from "@/hooks";
import { UnreadBadge } from "@/components";
import { DraftAR, PermitReview, ReviewRequired, UnderReview } from "@/components/my-requests/dashboards";

const titleStyle: SxProps = {
  fontSize: "1.2em"
};

export function ExistingApprovalRequestType() {
  const { notifications } = useNotifications(false, undefined, undefined, [NotificationType.Unread]);

  const [value, setValue] = useState("1");

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { isRequestor, isCoordinator, isEndorser } = useAuthorization();

  const tabs = [
    {
      tabName: "Draft",
      enabled: true,
      component: <DraftAR />,
      badge: notifications.some(
        (notification) =>
          notification.event === NotificationEvent.ARReturned && notification.type === NotificationType.Unread
      )
    },
    {
      tabName: "Review Required",
      enabled: isCoordinator,
      component: <ReviewRequired />,
      badge: notifications.some((notification) => notification.event === NotificationEvent.ARSubmitted)
    },
    { tabName: isCoordinator ? "SME Review" : "Under Review", enabled: true, component: <UnderReview /> },
    {
      tabName: "Permit Review",
      enabled: isEndorser || isCoordinator,
      component: <PermitReview />,
      badge: notifications.some((notification) => notification.event === NotificationEvent.ARPermitDrafted)
    },
    { tabName: "Permit", enabled: isRequestor, component: <>Permit</> },
    { tabName: "Expired", enabled: isRequestor, component: <>Expired</> },
    { tabName: "Declined", enabled: isRequestor, component: <>Declined</> }
  ];

  return (
    <Box>
      <Typography sx={titleStyle} gutterBottom variant="h6">
        Existing
      </Typography>
      <br />
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="Existing tab">
              {tabs.map(
                ({ tabName, enabled, badge }, index) =>
                  enabled && (
                    <Tab
                      key={`${tabName}-${index}`}
                      label={
                        <UnreadBadge visible={badge ?? false} marginLeft="-0.5rem">
                          {tabName}
                        </UnreadBadge>
                      }
                      value={`${index + 1}`}
                    />
                  )
              )}
            </TabList>
          </Box>
          {tabs.map(({ tabName, component }, index) => (
            <TabPanel key={`${tabName}-${index}`} value={`${index + 1}`}>
              {component}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Box>
  );
}
