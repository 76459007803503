import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { GridColDef, GridEventListener, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { APPROVAL_REQUEST_PATH, DEFAULT_AR_FILTER_QUERY } from "@/constants";
import { ApprovalRequestStatus } from "@/interfaces";
import { flattenPaginatedARList, formatDate, renderDisciplineStatusIcon } from "@/utils";
import { useAuthorization } from "@/context";
import { useApprovalRequestList, usePagination } from "@/hooks";
import { ARFilterToolbar, ARFilterQuery } from "@/components/toolbars";

export const UnderReview = () => {
  const navigate = useNavigate();

  const { isCoordinator } = useAuthorization();
  const { currentPage, pageSize, paginationModelChangeHandler } = usePagination();

  const [sortField, setSortField] = useState<string>("requiredByDate asc");
  const [filterQuery, setFilterQuery] = useState<ARFilterQuery>(DEFAULT_AR_FILTER_QUERY);

  const approvalRequestStatusFilter = useMemo(
    () =>
      isCoordinator
        ? [ApprovalRequestStatus.Distributed]
        : [ApprovalRequestStatus.Distributed, ApprovalRequestStatus.Submitted],
    [isCoordinator]
  );

  const { data: approvalRequestList, isLoading } = useApprovalRequestList({
    approvalRequestStatus: approvalRequestStatusFilter,
    pageNumber: currentPage + 1,
    pageSize,
    sortField,
    hubId: filterQuery.selectedHub,
    projectId: filterQuery.selectedProject,
    siteId: filterQuery.selectedSite
  });

  const dataColumns: GridColDef[] = [
    {
      field: "referenceNo",
      headerName: "AR Number",
      flex: 0.25
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1
    },
    {
      field: "heritage",
      headerName: "H",
      description: "Heritage",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "csp",
      headerName: "CSP",
      description: "CSP",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "biological",
      headerName: "B",
      description: "Biological",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "environmentalOps",
      headerName: "EO",
      description: "Environmental Ops",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "tenure",
      headerName: "T",
      description: "Tenure",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "stateAgreement",
      headerName: "SA",
      description: "State Agreement",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "miningAct",
      headerName: "M",
      description: "Mining Act",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "water",
      headerName: "W",
      description: "Water",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "environmentalApprovals",
      headerName: "EA",
      description: "Environmental Approvals",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "partV",
      headerName: "V",
      description: "Part V",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "nvcp",
      headerName: "NVCP",
      description: "Native Vegetation Clearing Permit",
      headerAlign: "center",
      sortable: false,
      flex: 0.15,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "shire",
      headerName: "Sh",
      description: "Shire",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 0.35
    },
    {
      field: "requiredByDate",
      headerName: "Required By",
      valueFormatter: (params) => formatDate(params.value),
      flex: 0.35
    }
  ];

  const arListWithDisciplineStatuses = useMemo(
    () => flattenPaginatedARList(approvalRequestList),
    [approvalRequestList]
  );

  const handleRowClick: GridEventListener<"rowClick"> = async ({ row: { id } }: GridRowParams) => {
    navigate(generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: id }));
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortClause = sortModel
      .map(({ field, sort }) => {
        return `${field} ${sort}`;
      })
      .join(",");

    setSortField(sortClause);
  };

  return (
    <DataGridPro
      autoHeight={true}
      columns={dataColumns}
      data-testid="under-review-table"
      density="compact"
      disableColumnMenu={true}
      loading={isLoading}
      onPaginationModelChange={paginationModelChangeHandler}
      onRowClick={handleRowClick}
      onSortModelChange={handleSortModelChange}
      pageSizeOptions={[10, 25, 50, 100]}
      pagination={true}
      paginationMode="server"
      paginationModel={{
        pageSize: approvalRequestList.pageSize,
        page: approvalRequestList.pageNumber - 1
      }}
      rowCount={approvalRequestList.totalRecords ?? 0}
      rows={arListWithDisciplineStatuses}
      slots={{ toolbar: ARFilterToolbar }}
      slotProps={{
        toolbar: {
          onFilterChange: (filterQuery: ARFilterQuery) => setFilterQuery(filterQuery),
          approvalRequestStatusFilter
        }
      }}
      sortingMode="server"
      sx={{ border: 0, cursor: "pointer" }}
    />
  );
};
